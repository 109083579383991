 

export default function Partnership() {
  return (
    <>
      <section id={'events-section'} className="section p-xs section-orange section-partnership">
        <div className="section-container">
          <div className="section-partnership-inner">
            <div className="left-side">
              <img src="/assets/images/logo/logo-icon-white.svg" alt="..." width={310} height={513} />
            </div>
            <div className="right-card">
              <img className="logo" src="/assets/images/logo/logo-without-icon.svg" alt="..." width={487} height={104} />
              <span className="primary-title">İş Ortağımız olmak ister misiniz?</span>
              <div className="desc">
                <p>Düzenlediğiniz atolye, kamp, kurs ve tiyatroların TotiliPotili'de yer alması için başvurabilirsiniz.</p>
              </div>
              <a href={'/partnership'} className="btn btn-width-arrow">
                <span className="text">Şimdi Başvur</span>
                <span className="icon">
                  <img src="/assets/images/icon/circle-orange.svg" alt="Orange Circle" width={77} height={75} />
                  <img src="/assets/images/icon/long-right-white-arrow.svg" alt="..." width={32} height={25} />
                </span>
              </a>
            </div>
          </div>
        </div>
        <div className="section-partnership-img">
          <img src="/assets/images/right-child-image.png" alt="..." width={274} height={728} /> 
        </div>
      </section>
      <section className={'section-pattern orange reverse'}></section>
    </>
  )
}
