'use client'
import React, { useLayoutEffect, useState, useEffect } from 'react'

// import Swiper core and required modules
import { Pagination, A11y } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { getBlogs } from '../services/api'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

const breakpoints = {
  // ekrana göre özelleştirebilirsiniz
  0: {
    slidesPerView: 1,
    spaceBetween: 10,
  },
  992: {
    slidesPerView: 3,
    spaceBetween: 10,
  },
}

export default function Blog() {
  const [data, setData] = useState([])

  useEffect(() => {
    async function getData() {
      await getBlogs().then((response) => {
        console.log('Blogs :', response)
        setData(response.response)
      })
    }
    getData()
  }, [])

  return (
    <>
      <section id={'events-section'} className="section p-xs section-white">
        <h1 className="section-title">Blog</h1>
        <div className="section-container">
          <Swiper className="swiper-has-box-shadow" modules={[Pagination, A11y]} breakpoints={breakpoints}
          // onSwiper={(swiper) => console.log(swiper)} onSlideChange={() => console.log('slide change')}
          >
            {data &&
              data.map((item, index) => (
                <SwiperSlide>
                  <div className="blog-card type-1">
                    <a href={'/blog/'+item.id} className="img">
                      <img src={item.image} alt="..." width={350} height={250} />
                    </a>
                    <div className="detail">
                      <a href={'/blog/'+item.id}>
                        <h3 className="primary-title">{item.title}</h3>
                      </a>
                      <span className="bottom-huge">
                        <a href={'/blog/'+item.id} className="btn btn-rounded btn-orange">
                          Devamını Oku
                        </a>
                      </span>
                    </div>
                  </div>
                </SwiperSlide>
              ))}

             
          </Swiper>
        </div>
      </section>
    </>
  )
}
