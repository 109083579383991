'use client'
import { useEffect, useRef, useState, useLayoutEffect } from 'react'
import Breadcrumb from '../components/Breadcrumb'
import { signUp } from '../services/api'
import BlogCard from '../components/BlogCard'
import SuccessModal from '../components/SuccessModal'
import Input from '../components/Input'
import Validation from '../components/Validation'
import { PatternFormat } from 'react-number-format'
import { useCookies } from 'react-cookie'
import Select from '../components/Select'

import { getAllCities } from "../services/api"

function SignUp() {
  const [userName, setUserName] = useState('')
  const [userSurname, setUserSurname] = useState('')
  const [phone, setPhone] = useState('')
  const [dateOfBirth, setDateOfBirth] = useState('')
  const [email, setEmail] = useState('')
  const [userPassword, setUserPassword] = useState('')
  const [isPasswordText, setIsPasswordText] = useState(false)
  const [modal, setModal] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [isNewsPasswordText, setIsNewsPasswordText] = useState(false)
  const [kvkk, setKvkk] = useState(false)
  const [contracts, setContracts] = useState(false)
  const [validationState, setValidationState] = useState(false)
  const [validationText, setValidationText] = useState('')
  const [city, setCity] = useState('')
  const [loading, setLoading] = useState(false)
  const [validationTitle, setValidationTitle] = useState('Hata')
  const [cityOptions, setCityOptions] = useState({})

  const [cookies, setCookie] = useCookies(['access_token', 'userData'])

  useLayoutEffect(() => {
    async function getData() {
      await getAllCities().then((response) => {
        setCityOptions([
          { value: '0', label: 'Şehir Seçiniz' },
          ...response
        ]);
      })
    }
    getData()
  }, [])

  const userNameChange = (e: any) => {
    setUserName(e.target.value)
  }

  const phoneChange = (e: any) => {
    setPhone(e.target.value)
  }

  const dateOfBirthChange = (e: any) => {
    setDateOfBirth(e.target.value)
  }

  const emailChange = (e: any) => {
    setEmail(e.target.value)
  }

  const cityChange = (e: any) => {
    setCity(e.label)
  }

  const passwordChange = (e: any) => {
    setUserPassword(e.target.value)
  }

  const newPasswordChange = (e: any) => {
    setNewPassword(e.target.value)
  }

  const kvkkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKvkk(e.target.checked)
  }

  const contractsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setContracts(e.target.checked)
  }

  const userSurnameChange = (e: any) => {
    setUserSurname(e.target.value)
  }

  const handleChange = (e: any) => {
    e.preventDefault()

    if (userName === '') {
      setValidationText('Lütfen Adınızı giriniz.')
      setValidationState(true)
      return false
    }

    if (userSurname === '') {
      setValidationText('Lütfen Soyadınızı giriniz.')
      setValidationState(true)
      return false
    }

    if (email === '') {
      setValidationText('Lütfen E-posta adresinizi giriniz.')
      setValidationState(true)
      return false
    }

    if (phone === '') {
      setValidationText('Lütfen Telefon Numaranızı giriniz.')
      setValidationState(true)
      return false
    }

    if (dateOfBirth === '') {
      setValidationText('Lütfen çocuğunuzun doğum yılını giriniz.')
      setValidationState(true)
      return false
    }

    if (userPassword === '') {
      setValidationText('Lütfen Şifre giriniz.')
      setValidationState(true)
      return false
    }

    if (newPassword === '') {
      setValidationText('Lütfen Şifresinizi tekrar giriniz.')
      setValidationState(true)
      return false
    }

    if (userPassword !== newPassword) {
      setValidationText('Şifreler Uyumlu Değil!')
      setValidationState(true)
      return false
    }

    if (!kvkk) {
      setValidationText('Lütfen gerekli belgeleri onaylayın!')
      setValidationState(true)
      return false
    }

    if (!contracts) {
      setValidationText('Lütfen gerekli belgeleri onaylayın!')
      setValidationState(true)
      return false
    }

    setLoading(true);

    async function getData() {
      await signUp(userName, userSurname, email, phone, dateOfBirth, userPassword, newPassword, city).then((response) => {
        setLoading(false); 
        if (response.err === null) {
          setValidationTitle('Başarılı');
          setValidationText('Üyelik işleminiz başarılı lütfen giriş yapın.')
          setValidationState(true)

          console.log("response", response)
          let expires = new Date()
          expires.setTime(expires.getTime() + (60 * 60 * 60 * 1000))
          setCookie('access_token', response.response.token, { path: '/', expires })
          setCookie('userData', response.response, { path: '/', expires })
          window.location.href = "/";

        } else {
          setValidationTitle('Hata');
          setValidationText('Üyelik işleminiz başarısız oldu lütfen tekrar deneyin.')
          setValidationState(true)
        }

      })
    }
    getData()
  }



  return (
    <>
      <main id="sign-up-main">
        <section className={'section-pattern white reverse'}></section>
        <section className="sign-up-section">
          <div className="container">
            <div className="main-inner">
              <h1 className="heading-page-title">Üye ol</h1>
              <form onSubmit={handleChange} className="two-col-form-container form-container sign-up mt">
                <div className="form-group">
                  <label className="label" htmlFor="userName">
                    Ad:
                  </label>
                  <Input type={'text'} autoComplete="off" className={'textbox'} id={'title'} name={'userName'} isReadOnly={loading ? true : false} value={userName ? userName : ''} onChange={userNameChange} />
                </div>
                <div className="form-group">
                  <label className="label" htmlFor="userSurname">
                    Soyad:
                  </label>
                  <Input type={'text'} className={'textbox'} id={'userSurname'} name={'userSurname'} isReadOnly={loading ? true : false} value={userSurname ? userSurname : ''} onChange={userSurnameChange} />
                </div>
                <div className="form-group">
                  <label className="label" htmlFor="phone">
                    Telefon:
                  </label>
                  <PatternFormat format="+90 ### ### ## ##" allowEmptyFormatting mask="_" className={'textbox'} id={'phone'} name={'phone'} value={phone ? phone : ''} onChange={phoneChange} />
                </div>
                <div className="form-group">
                  <label className="label" htmlFor="dateOfBirth">
                    Çocuğunuzun Doğum Tarihi:
                  </label>
                  <PatternFormat
                    format="##-##-####"
                    allowEmptyFormatting
                    mask="_"
                    className={'textbox'}
                    id={'dateOfBirth'}
                    name={'dateOfBirth'}
                    value={dateOfBirth ? dateOfBirth : ''}
                    onChange={dateOfBirthChange}
                  />
                </div>
                <div className="form-group">
                  <label className="label" htmlFor="email">
                    E-posta:
                  </label>
                  <Input type={'text'} className={'textbox'} id={'email'} name={'email'} isReadOnly={loading ? true : false} value={email ? email : ''} onChange={emailChange} />
                </div>
                <div className="form-group custom-select">
                  <label className="label" htmlFor="city">
                    Oturduğunuz İl:
                  </label>
                  <Select options={cityOptions} className="select textbox" id={'city'} name={'city'} isReadOnly={loading ? true : false} onChange={cityChange} />
                </div>
                <div className="form-group">
                  <label className="label" htmlFor="userPassword">
                    Şifre:
                  </label>
                  <div className="icon-huge">
                    <Input
                      type={isPasswordText ? 'text' : 'password'}
                      className={'textbox'}
                      id={'userPassword'}
                      name={'userPassword'}
                      value={userPassword ? userPassword : ''}
                      onChange={passwordChange}
                      isReadOnly={loading ? true : false}
                    />
                    <button
                      type="button"
                      className={`icon password ${!isPasswordText && 'open'}`}
                      id={'passwordToggle'}
                      onClick={(e) => {
                        setIsPasswordText(!isPasswordText)
                      }}>
                      <img src="/assets/images/icon/show-hide.svg" width={24} height={24} alt=" " />
                    </button>
                  </div>
                </div>
                <div className="form-group">
                  <label className="label" htmlFor="newPassword">
                    Şifre Tekrar:
                  </label>
                  <div className="icon-huge">
                    <Input
                      type={isNewsPasswordText ? 'text' : 'password'}
                      className={'textbox'}
                      id={'newPassword'}
                      name={'newPassword'}
                      value={newPassword ? newPassword : ''}
                      onChange={newPasswordChange}
                      isReadOnly={loading ? true : false}
                    />
                    <button
                      type="button"
                      className={`icon password ${!isNewsPasswordText && 'open'}`}
                      id={'passwordToggleNew'}
                      onClick={(e: any) => {
                        setIsNewsPasswordText(!isNewsPasswordText)
                      }}>
                      <img src="/assets/images/icon/show-hide.svg" width={24} height={24} alt=" " />
                    </button>
                  </div>
                </div>

                <label htmlFor={`kvkk`} className="form-group checkbox full">
                  <Input type={'checkbox'} id={`kvkk`} className="" name="kvkk" isReadOnly={loading ? true : false} checked={kvkk ? true : false} onChange={kvkkChange} />
                  <span>
                    Kampanyalardan ve bildirimlerden haberdar olmak için, <a href={'/page/gizlilik-sozlesmesi-kvkk'}>Gizlilik ve KVKK</a> kapsamında elektronik ileti almak istiyorum.
                  </span>
                </label>
                <label htmlFor={`contracts`} className="form-group checkbox full">
                  <Input type={'checkbox'} id={`contracts`} className="" name="contracts" isReadOnly={loading ? true : false} checked={contracts ? true : false} onChange={contractsChange} />
                  <span>
                    <a target='blank' href={'/page/kullanici-sozlesmesi'}>Kullanıcı Sözleşmesini</a>, <a href="/page/mesafeli-satis-sozlesmesi">Mesafeli Satış Sözleşmesi</a>, <a target='blank' href={'/page/on-bilgilendirme-metni'}>Ön Bilgilendirme Metni</a> ve <a target='blank' href={'/page/iptal-iade-degisim-kosullari'}>İptal, iade ve Değişim Koşulları</a> kabul ettiğimi onaylıyorum.
                  </span>
                </label>
                <div className="form-group btn-group">
                  <button
                    type="submit"
                    className={`btn btn-rounded btn-orange btn-loading ${loading && 'btn-loading-pending'}`}
                  // onClick={(e: any) => {
                  //   setModal(!modal)
                  // }}
                  >
                    <i className="spin"></i>
                    Üye Ol
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </main>

      {modal && <SuccessModal setEvent={setModal} />}
      {validationState && <Validation setEvent={setValidationState} text={validationText} title={validationTitle} />}
    </>
  )
}

export default SignUp
