import Hero from '../components/hero';
import Categories from '../components/categories';
import Events from '../components/events';
import Blog from '../components/blog';
import Partnership from '../components/partnership';
 

function Home() {
  return (
    <> 
      <Hero />
      <Categories />
      <Events />
      <Blog />
      <Partnership /> 
    </>
  );
}

export default Home;
