function Page() {
    return (
      <>
        <main id="blog-detail-main">
          <section className={'section-pattern white reverse'}></section>
          <section className="section-page-title">
            <div className="container">
              <div className="main-inner">
                <div>
                  <h1 className="primary-title">ARACI/KOMİSYON SÖZLEŞMESİ</h1>
                </div>
              </div>
            </div>
          </section>
          <section className="section-blog-detail">
            <div className="container">
              <div className="editor-content">
                <strong>1. SÖZLEŞMENİN TARAFLARI</strong>
                <p>
                  Bir tarafta “Ferahevler Mah. Önceler Sok. No: 3 İç Kapı No: 1 Sarıyer/İSTANBUL” adresinde bulunan GÜLŞAH AZAKLI GÜNAY (bundan sonra www.totilipotili.com ve totilipotili aplikasyonu olarak anılacaktır.) diğer tarafta www.totilipotili.com internet sitesinde veya totilipotili
                  aplikasyonunda yer alan etkinlikleri düzenleyen ORGANİZATÖR aşağıdaki şartlar doğrultusunda işbu sözleşmeyi akdetmişlerdir.
                </p>
                <strong>2. SÖZLEŞMENİN KONUSU</strong>
                <p>
                  www.totilipotili.com ve totilipotili aplikasyonu, Türkiye’de çocuklara fayda sağlayacak atölyeler, kurslar, tiyatrolar, kamplar ve diğer etkinlikler düzenleyen organizasyonlar için çevrimiçi bilet satış platformu olup, bilet satışına aracılık hizmeti sağlayan bir platformdur.
                  Organizatör, Türkiye Cumhuriyeti sınırları içerisinde Türkiye Cumhuriyeti yasalarının izin verdiği ölçüde kültürel ve eğlence amaçlı etkinlikler düzenleyen ve organize eden gerçek veya tüzel kişidir. İşbu sözleşme, Organizatör’ün düzenleyeceği etkinliklerin bilet satışını
                  gerçekleştirirken www.totilipotili.com’un ve totilipotili aplikasyonu’nun satış ağından yararlanma şartlarını düzenleme amacıyla hazırlanmıştır.
                </p>
                <strong>3. BİLET SATIŞI VE ORGANİZATÖRLERE İLİŞKİN GENEL ŞARTLAR </strong>
                <p>
                  <b>a)</b> www.totilipotili.com veya totilipotili aplikasyonu etkinliği düzenleyen Organizatör adına bilet satmakta ve satılan bilet başına hizmet bedelini tahsil etmekte olup, bilet fiyatlarını belirleme konusunda hak ve yetkiye sahip değildir.
                </p>
                <p>
                  <b>b)</b> www.totilipotili.com veya totilipotili aplikasyonu ve Organizatör’ün anlaşması üzerine belirleyeceği bir bankada hesap açılacak ve anlaşmalı banka nezdinde bilet satışlarından elde edilen meblağlar bu hesapta tutulacaktır.
                </p>
                <p>
                  <b>c)</b> www.totilipotili.com ve totilipotili aplikasyonu, Organizatör’ün düzenlediği etkinliklerde ücretli her bir atölye, kurs ve tiyatro bileti üzerinden Organizatör’ün etkinlik sayfası oluştururken belirlemiş olduğu bilet bedeli üzerinden (Tüm ödenebilir Devlet ve Belediye
                  vergileri dahil) %20 oranında ve kamp biletleri üzerinden (Tüm ödenebilir Devlet ve Belediye vergileri dahil) %10 oranında komisyon bedeli hesaplayacak ve Organizatör’ün hesabından bu komisyon bedelini mahsup edecektir. Bu ticaretten oluşan hizmet bedeli faturası ise yine
                  Organizatör’ün www.totilipotili.com internet sitesine veya totilipotili aplikasyonuna üye olurken kaydetmiş olduğu e-posta adresine e-fatura/e-arşiv yöntemi ile iletilecektir. Organizatör tüzel kişi ise fatura oluşturabilmek için şirket ticari unvanı, ticaret sicilde kayıtlı adres
                  bilgileri, vergi dairesi ve vergi numarası, Organizatör gerçek kişi ise T.C. kimlik numarası ve ikamet ettiği açık adres bilgilerinin sisteme kaydedilmesi zorunludur. Bu bilgilerin sistemde kayıtlı olmaması durumunda Organizatör kayıt bilgilerini sisteme kaydedene kadar
                  www.totilipotili.com veya totilipotili aplikasyonu tarafından Organizatör’e herhangi bir ödeme yapılmayacaktır. Sisteme bilgilerini kaydeden Organizatör’ün bilet satışı sonucu elde etmiş olduğu hizmet bedeli, Müşteriler’in herhangi bir itiraz veya şikâyette bulunmadıkları durumlarda
                  www.totilipotili.com veya totilipotili aplikasyonu tarafından, etkinlik gerçekleştikten 10 gün sonra Organizatör’ün hesabına transfer edilecektir.
                </p>
                <p>
                  <b>d)</b> Organizatör, www.totilipotili.com internet sitesi veya totilipotili aplikasyonu aracılığıyla gerçekleşen satışlar sonucunda Müşterilere bileti göndermek ve fatura kesmekle yükümlü olduğunu kabul ve taahhüt etmektedir. Organizatör’ün Müşterilere bileti göndermediğinin ve
                  faturayı teslim etmediğinin www.totilipotili.com veya totilipotili aplikasyonu tarafından tespit edildiği durumlarda www.totilipotili.com veya totilipotili aplikasyonu tarafından Organizatör'e ödeme gerçekleştirilmez ve www.totilipotili.com veya totilipotili aplikasyonu tarafından
                  Müşterinin www.totilipotili.com sisteminde veya totilipotili aplikasyonunda belirtmiş olduğu eposta adresine bilgilendirme yapılacaktır. Bu bilgilendirme yapıldıktan sonra 5 iş günü içinde Organizatör biletleri/faturaları Müşterilere ulaştırmazsa Müşterilere ücret iadesi hizmet
                  bedeli düşülerek gerçekleştirilecektir. Biletlerin/faturaların Müşterilere teslim edilmemesi gibi durumlardan kaynaklanacak zararlarda Organizatör, www.totilipotili.com’u veya totilipotili aplikasyonunu tazmin edeceğini kabul ve taahhüt etmektedir.
                </p>
                <p>
                  <b>e)</b> Organizatör’ün etkinlikleri belirli sebeplerle iptal etme veya tarihlerini değiştirme hakkı bulunmaktadır. Bu durumda www.totilipotili.com veya totilipotili aplikasyonu etkinliğin iptal edilmesinden veya tarihinin değiştirilmesinden sorumlu olmayacak olup, tüm sorumluluk
                  Organizatör’de olacaktır. İptal edilen veya tarihi değiştirilen etkinlikten Müşterileri haberdar etme sorumluluğu Organizatör’de olup, Organizatör’ün vereceği yetki doğrultusunda www.totilipotili.com’un veya totilipotili aplikasyonunun da iptal ve değişiklik konularında Müşterileri
                  bilgilendirme hakkı bulunmaktadır.
                </p>
                <p>
                  <b>f)</b> www.totilipotili.com veya totilipotili aplikasyonu, mahkemenin veya kolluk birimlerinin yazılı olarak Organizatör’ün özel ve ticari bilgilerini talep etmesi durumunda tüm bilgileri yetkili kişi veya kurumlarla paylaşacak olup, bu paylaşımlara ilişkin olarak Organizatör’ün
                  tazminat talep etme hakkı bulunmayacaktır.
                </p>
                <p>
                  <b>g)</b> www.totilipotili.com internet sitesinde veya totilipotili aplikasyonunda Organizatör’ün sağladığı bilgi, içerik ve tanıtım görsellerinin hukuka uygunluğu Organizatör’ün sorumluluğundadır. Bu bilgi ve içeriklerin hatalı olmasından kaynaklanacak hiçbir zarardan
                  www.totilipotili.com’un veya totilipotili aplikasyonunun sorumluluğu bulunmamaktadır. Ayrıca Organizatörlerin organizasyon tanıtım görsellerinde çocuk fotoğraflarını yüzleri görülecek şekilde paylaşmaları yasak olup, bu görsellerin kullanılması durumunda tüm hukuki ve cezai
                  sorumluluk Organizatör’e ait olacaktır.
                </p>
                <p>
                  <b>h)</b> Organizatör’ün sağladığı hizmet ve içeriklerinin güvenliği ve hukuka uygunluğuna ilişkin her türlü sorumluluk Organizatör’de olup, bu konuda www.totilipotili.com’un herhangi bir sorumluluğu bulunmamaktadır.
                </p>
                <strong>4. UYUŞMAZLIK ÇÖZÜMÜ</strong>
                <p>
                  Yukarıda yer alan şartların tamamı Türk Hukukuna tabi olup, işbu sözleşmeden dolayı ortaya çıkacak olan her türlü uyuşmazlık öncelikle sulh yoluyla giderilmeye çalışılacaktır. Buna rağmen sulh ile çözümlenemeyen uyuşmazlıkların çözümü konusunda İstanbul (Çağlayan) Mahkemeleri ve İcra
                  Daireleri yetkili olacaktır.
                </p>
              </div>
            </div>
          </section>
        </main>
      </>
    );
  }
  
  export default Page;