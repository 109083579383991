'use client'
import moment from 'moment';
import 'moment/locale/tr'  // without this line it didn't work
moment.locale('tr')
// {
//   "id": 16,
//   "name": "Test",
//   "city": "Bursa",
//   "county": "Nilüfer",
//   "district": null,
//   "capacity_lower_boundary": 1,
//   "capacity_upper_boundary": 55,
//   "age_group_low": "1",
//   "age_group_high": "3",
//   "is_online": false,
//   "organizer": 2,
//   "event_date": "2024-01-10T15:15:00.000Z",
//   "category": "Atölye",
//   "confirmed": true,
//   "description_text": "Test",
//   "created_date": "2023-12-30T11:54:12.887Z",
//   "updated_date": "2023-12-30T11:54:12.887Z",
//   "price": "2499",
//   "vendor_id": null
// }

export default function EventCard({ data }) {
  let date_day = moment(data.event_date).format('dddd');
  let date_mounth = moment(data.event_date).format('MMMM');
  let date_day_of_mounth = moment(data.event_date).format('D');
  let date_time = moment(data.event_date).format('h:mm');
  // console.log(moment.locale())

  return (
    <>
      <div className="event-card">
        <div className="card-main">
          <div>
            {data.category === "Atölye" && (
              <span className="date-tag">
                <strong>{date_day_of_mounth}</strong>
                <span className="month">{date_mounth}</span>
                <span className="day">{date_day}</span>
              </span>
            )}
            {data.category === "Kamp" && (
              <span className="date-tag">
                <strong>{moment(data.start_date).format('D')}</strong>
                <span className="month">{moment(data.start_date).format('MMMM')}</span>
                <span className="day">{moment(data.start_date).format('dddd')}</span>
              </span>
            )}
            {data.category === "Kurs" && (
              <span className="date-tag">
                <strong>{moment(data.start_date).format('D')}</strong>
                <span className="month">{moment(data.start_date).format('MMMM')}</span>
                <span className="day">{moment(data.start_date).format('dddd')}</span>
              </span>
            )}
            {data.category === "Tiyatro" && (
              <span className="date-tag">
                <strong>{date_day_of_mounth}</strong>
                <span className="month">{date_mounth}</span>
                <span className="day">{date_day}</span>
              </span>
            )}

            <div className="main-huge">
              <div className="top-huge">
                <span className="time-with-icon">

                  {data.start_time && (
                    <>
                      <img src="/assets/images/icon/clock.svg" alt="..." width={24} height={24} />
                      <span>{data.start_time}</span>
                    </>
                  )}
                </span>

                <span className="location">{data.county} / {data.city}</span>
              </div>
              <a className="primary-title" href={'/event/' + data.id}>
                {data.name}
              </a>
              <span className="company">{data.company_name}</span>
            </div>
          </div>
          <div>
            <span className="info-text">Yaş Grubu: {data.age_interval}</span>
            <div className="btn-group">
              <span className="price-badge">{data.price} ₺</span> 
              {data.pnr_no ? <>
                <span className="btn btn-rounded btn-orange">
                  {data.pnr_no}
                </span>
              </> : <>
                <a href={'/event/' + data.id} className="btn btn-rounded btn-orange">
                  İncele <img src="/assets/images/icon/long-right-arrow.svg" alt="..." width={13} height={10} />
                </a>
              </>}  
            </div>
          </div>
        </div>
        <a href={'/event/' + data.id} className="category-card">
          <img src={data.image} alt="Atolye" width={365} height={365} />
          <strong className="card-title">{data.category}</strong>
        </a>
      </div>
    </>
  )
}
