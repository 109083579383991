
function Page() {
  return (
    <>
      <main id="blog-detail-main">
        <section className={'section-pattern white reverse'}></section>
        <section className="section-page-title">
          <div className="container">
            <div className="main-inner">
              <div>
                <h1 className="primary-title">MESAFELİ SATIŞ SÖZLEŞMESİ</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="section-blog-detail">
          <div className="container">
            <div className="editor-content">
              <strong>1. SÖZLEŞMENİN TARAFLARI</strong>
              <p>
                Bir tarafta “Ferahevler Mah. Önceler Sok. No: 3 İç Kapı No: 1 Sarıyer/İSTANBUL” adresinde bulunan GÜLŞAH AZAKLI GÜNAY (bundan sonra www.totilipotili.com ve totilipotili aplikasyonu
                olarak anılacaktır.) diğer tarafta www.totilipotili.com internet sitesi veya totilipotili aplikasyonu üzerinden bilet satın alan Tüketici (“Müşteri” olarak anılacaktır) aşağıdaki
                şartlar doğrultusunda işbu sözleşmeyi akdetmişlerdir.
              </p>
              <strong>2. SÖZLEŞMENİN KONUSU</strong>
              <p>
                İş bu sözleşmenin konusu Müşteriye, www.totilipotili.com internet sitesi veya totilipotili aplikasyonu üzerinden elektronik ortamda, etkinlik bileti alınmasına ilişkin olarak bilet
                satılması hizmeti olup; Tüketicinin Korunması Hakkındaki Kanun ve Mesafeli Sözleşmelere Dair Yönetmelik hükümleri gereğince tarafların karşılıklı hak ve yükümlülüklerinin
                belirlenmesidir.
              </p>
              <strong>3. KULLANIM KOŞULLARI</strong>
              <p>
                Müşteri, totilipotili.com adlı internet sitesinde veya totilipotili aplikasyonunda yer alan “Kullanıcı Sözleşmesi” ve “Gizlilik Sözleşmesi” başlıklı metinleri okuduğunu ve işbu
                kullanım koşulları ve gizlilik maddeleri hakkında eksiksiz bilgiye sahip olduğunu işbu Sözleşme ile kabul ve beyan etmektedir.
              </p>
              <strong>4. SATIŞ VE HİZMETE İLİŞKİN GENEL ŞARTLAR</strong>
              <p>a) Müşteri, etkinlik biletinin fiyatını internet sitesi veya aplikasyon üzerinden görebilecektir.</p>
              <p>
                b) Etkinlik biletleri, www.totilipotili.com veya totilipotili aplikasyonu üzerinden yalnızca banka kartı veya kredi kartı ile satın alınabilir. Müşterinin, internet veya aplikasyon
                üzerinden bilet satın alması halinde kendisine ödeme yapmak üzere, tercih etmesi için kredi kartı veya banka kartı seçenekleri sunulacaktır. Müşteri, bu kredi kartı veya banka kartı
                seçeneğinden kendisine uygun olanı tercih edecek işlemini tamamlayacaktır.
              </p>
              <p>
                c) Bilet fiyatlarına tüm vergi ve harçlar dâhil olup, internet bilet satış hizmetine ilişkin ücrette www.totilipotili.com ve totilipotili aplikasyonu’nun değişiklik yapma hakkı
                saklıdır. İnternet veya aplikasyon bilet satış hizmetine ilişkin ücrette yapılacak değişiklikleri Müşteri kabul ve beyan etmektedir.
              </p>
              <p>
                d) www.totilipotili.com ve totilipotili aplikasyonu tarafından işlem gören her bilet için, www.totilipotili.com ve totilipotili aplikasyonu tarafından belirlenen bir hizmet bedeli
                alınmaktadır. Kullanıcı www.totilipotili.com veya totilipotili aplikasyonu üzerinden bilet satın almakla bu bedelleri ödemeyi kabul etmiş sayılacaktır.
              </p>
              <p>e) Gününde ve saatinde kullanılmayan etkinlik biletleri geçersiz olup, hizmet bedeli iadesi ve/veya değişikliği yapılması mümkün değildir.</p>
              <p>
                f) Müşteri, www.totilipotili.com ve totilipotili aplikasyonu’nun belirlediği bilet sayısından fazla bilet satın alamaz. www.totilipotili.com ve totilipotili aplikasyonu bu uygulama ile
                olası kötü niyetli bilet satışlarını önlemektedir. Müşteri, satın almış olduğu biletleri karaborsa yolu ile kanuna aykırı olarak satamayacaktır.
              </p>
              <p>
                g) www.totilipotili.com ve totilipotili aplikasyonu ile birlikte www.totilipotili.com ve totilipotili aplikasyonu’nun tüm ortakları, çalışanları ya da içerik sağlayıcıları, sebebi ve
                süresinden bağımsız olarak www.totilipotili.com ve totilipotili aplikasyonunda ortaya çıkabilecek olası hata, kusur ve ihmallerden veya bilgi eksikliğinden ve gecikmeden dolayı meydana
                gelebilecek zarardan sorumlu tutulamaz. www.totilipotili.com ve totilipotili aplikasyonu ile birlikte www.totilipotili.com ve totilipotili aplikasyonunun tüm ortakları, çalışanları ya
                da içerik sağlayıcıları, Müşterinin ve üçüncü şahısların tazminat, ceza ve sair ve her ne nam altında olursa olsun talep edecekleri alacak taleplerinden dolayı sorumluluk kabul
                etmemektedir.
              </p>
              <p>
                h) Alınan etkinlik biletlerine ilişkin olarak kontrol etme, dekont muhafaza etme yükümlülüğü Müşteriye aittir. Bu yükümlülüğün yerine getirilmemesinden doğan zararlardan Müşteri
                sorumlu olacaktır.
              </p>
              <p>
                ı) www.totilipotili.com veya totilipotili aplikasyonu üzerinden etkinlik bileti alan Müşteri; satın alınmış her etkinlik bileti için seçmiş olduğu koltuğu kabul etmiş,
                www.totilipotili.com ve totilipotili aplikasyonunun uygun gördüğü ve/veya teknik aksaklıklar olması halinde etkinlik bileti satmayı reddetme, etkinlik biletleri satışını feshetme ya da
                ders saatlerini ve salonları değiştirme/iptal etme hakkının saklı olduğunu kabul etmiş bulunmaktadır.
              </p>
              <p>
                i) www.totilipotili.com veya totilipotili aplikasyonu üzerinden satın alınmış olan etkinlik biletlerinin, iade, iptal veya değişimi işbu Sözleşmede belirlenen şartlar doğrultusunda
                etkinlik seansından 5 gün önceye kadar www.totilipotili.com adresinden veya belirtilen usullerde yapılabilir.
              </p>
              <strong>5. CAYMA HAKKI</strong>
              <p>
                Müşteri, işbu sözleşmenin akdedildiği tarihten itibaren yedi gün içerisinde herhangi bir gerekçe göstermeksizin sözleşmeden cayma hakkına sahiptir. Müşterinin cayma hakkını
                kullanabilmesi için satın almış olduğu hizmeti hiçbir şekilde kullanmamış olması ve/veya cayma hakkı süresi olan 7 günlük süreyi aşmamak kaydıyla etkinlik seansının başlamasına 5
                günden fazla zaman kalması gerekmektedir, aksi halde cayma hakkı geçersiz olacaktır. Müşterinin cayma hakkını süresi içerisinde ve usulüne uygun kullanması halinde www.totilipotili.com
                ve totilipotili aplikasyonu cayma hakkı bildiriminin kendisine ulaştığı tarihten itibaren en geç on dört gün içerisinde almış olduğu bilete ilişkin bedeli Müşteriye iade edecektir.
                Müşteri, cayma hakkı kullanımını yazılı olarak yapacak veya www.totilipotili.com ve totilipotili aplikasyonunun belirtilen iletişim formuna veya e-posta adresine bildirerek
                gerçekleştirecektir.
              </p>
              6. DİĞER HÜKÜMLER Bilet satışına ilişkin bilgiler totilipotili.com tarafından kayıt altında tutulacak ve herhangi bir uyuşmazlık halinde yapılan satışa ilişkin kayıtlar ilgili kurum ve
              kuruluşlara sunulacaktır. www.totilipotili.com sahibi olduğu internet sitesinde veya totilipotili aplikasyonunda oluşabilecek sistem hatalarından kaynaklanan fiyat ve içerik hatalarından
              sorumlu değildir. Müşteri, siteyi veya aplikasyonu kullanımı sırasında işbu Sözleşme ve Kullanıcı Sözleşmesi ile sitede yer alan tüm şartlara, sitenin ilgili yerlerinde yapılan
              açıklamalara ve yürürlükteki tüm mevzuata uygun hareket edeceğini aksi halde bu bildirimlere ve yasalara aykırı kullanım sebebiyle doğabilecek hukuki, cezai ve mali her türlü
              sorumluluğun kendisine ait olduğunu kabul, beyan ve taahhüt etmektedir. 7. UYUŞMAZLIK ÇÖZÜMÜ İşbu sözleşmenin uygulanmasından doğan uyuşmazlıklarda Bakanlık tarafından belirlenen parasal
              sınırlar dâhilinde Müşteri’nin hizmet satın aldığı veya ikametgâhının bulunduğu yerdeki Tüketici Sorunları Hakem Heyeti yetkilidir. Bu şart ve koşulların tamamı Türk Hukukuna tabidir.
              Tarafımızca biletinizi satın almadan önce sözleşme hükümleri ve içeriği konusunda, tüm tüketiciler önceden bilgilendirilmiş ve ön bilgilendirme yükümü yerine getirilmiş olup, bileti
              satın alarak ve siparişi onaylayarak, işbu sözleşmenin düzenlemeleri ve Müşteri tarafından satın alınan hizmet hakkında Müşteri’ye ön bilgilendirme yapıldığı da kabul edilmiş
              sayılacaktır. Bu madde yazılı delil vasfında ispat vasıtasıdır. İşbu Sözleşmeden ve/veya hizmet ya da bilet satın alma işlemlerinden dolayı herhangi bir ihtilafın doğması durumunda,
              uyuşmazlığı öncelikle sulhen gidermeye çalışmak temel hedefimizdir. Buna karşın, sulh ile çözülemeyen ve uyuşmazlığın devam ettiği durumlarda, ilgili mevzuatta yayımlanan parasal
              sınırlar çerçevesinde Tüketici Hakem Heyetleri ve/veya Tüketici Mahkemeleri yetkili olacaktır. 8. YÜRÜRLÜK İşbu sözleşmenin kabul edilerek onaylanması ve internet sitesi veya aplikasyon
              üzerinden verilen siparişe ait ödemenin gerçekleşmesi ile Müşteri, işbu sözleşmenin tüm koşullarını kabul etmiş sayılacak ve iş bu sözleşme yürürlüğe girecektir. Müşteri, sözleşme konusu
              hizmetin nitelikleri, satış fiyatı, ödeme şekli, sözleşmeden cayma hakkı ve sözleşmenin getirdiği tüm hak ve yükümlülükler konusunda doğru ve eksiksiz olarak bilgi sahibi olduğunu
              herhangi bir itirazı olmadığını kabul, beyan ve taahhüt etmektedir.
            </div>
          </div>
        </section>
      </main>
    </>
  )
}

export default Page
