import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getVendorById } from '../services/api'

function EventDetail() {
  const [data, setData] = useState([])
  const { id } = useParams();

  useEffect(() => {
    async function getData() {
      await getVendorById(id).then((response) => {
        if (response?.response) {
          console.log(response.response);
          setData(response.response)
        }

      })
    }
    getData()

  }, [])

  return (
    <main id="company-detail-main">
      <section className={'section-company-title-box'}>
        <div className="container">
          <div className="info-card">
            <div className="img">
              <img src={data.image} alt="..." />
            </div>
            <div className="detail">
              <div className="badge badge-primary">
                <img src="/assets/images/icon/star-light.svg" alt="..." width={25} height={25} />
                <span>İş Ortağı</span>
              </div>
              <h1 className="primary-title">{data.company_name}</h1>
            </div>
          </div>
        </div>
      </section>
      <section className={'section-pattern reverse'}></section>
      <section className="content-section">
        <div className="container">
          <h2 className="heading-3">Hakkında</h2>
          <div className="editor-content mb-content">
            {data.about}
          </div>
          <span className="heading-3">İletişim Bilgileri</span>
          <div className="contact-info-list">
            {data.address && (
              <div>
                <strong>Adres:</strong>
                <div className="editor-content">
                  <p>{data.address}</p>
                </div>
              </div>
            )}
            {data.phone && (
              <div>
                <strong>Telefon:</strong>
                <div className="editor-content">
                  <a href={"tel:"+data.phone} target={'_blank'} rel="noreferrer">
                    {data.phone}
                  </a>
                </div>
              </div>
            )}
            {data.email && (
              <div>
                <strong>Mail:</strong>
                <div className="editor-content">
                  <p>
                    <a href={"mailTo:"+ data.email} target={'_blank'} rel="noreferrer">
                      {data.email}
                    </a>
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      <div className="container"></div>
    </main>
  )
}

export default EventDetail
