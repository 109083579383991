import Breadcrumb from '../components/Breadcrumb';


function About() {
  return (
    <>
      <main id="about-detail-main">
        <Breadcrumb title="Hakkımızda" />
        <section className="section-about-detail">
          <div className="container">
            <div className="main-inner">
              <div className="editor-content">
                <h2>Bir küçük TotiliPotili hikayesi</h2>
                <p>
                  Aslında kelime olarak Türkçe ya da başka bir dilde bir karşılığı olmamakla birlikte bendeki anlamı oldukça <b>derin</b> ve <b>değerli</b>. Oğlum Bulut dünyaya geldiğinde anneannesi
                  O’na hep TotiliPotili diye seslendi, O’nu hep böyle sevdi… Bu hitabın sıcaklığı, içtenliği O’na o kadar yakıştı ki bir süre sonra yakın çevremizdeki herkesin Bulut’a böyle seslenmeye
                  başladığını fark ettik.
                </p>
                <p>
                  Bu vesile ile eğer bir gün kendi işimi kurarsam hayatımdaki tüm emekleri, fedakarlıkları ve bana, aileme kattıkları için anneme teşekkür etmek amacıyla bu ismi kullanacağıma kendi
                  kendime söz verdim.
                </p>
                <p>İşte böyle doğdu TotiliPotili ismi, hikayesi…</p>

                <div className="image-box-vertical in-about">
                  <img src="/assets/images/about/gulsah-azakli-gunay.jpg" alt="..." width={350} height={250} />
                </div> 
                <h2>Kurucumuz</h2>
                <p>
                  Ben Gülşah Azaklı Günay, 1988 yılında İstanbul’da doğdum. İstanbul Üniversitesi Kamu Yönetimi bölümünden mezun olduktan sonra yüksek lisansımı Marmara Üniversitesi İnsan Kaynakları
                  Yönetimi alanında yaptım ve İnsan Kaynakları alanında uzmanlaştım.
                </p>
                <p>
                  Çalıştığım farklı sektörlerde ve alanlarda beni heyecanlandıran, değer katmak, kendimi geliştirmek için sabırsızlandığım bir işle karşılaşmadım. Bu sebeple tutkumun peşinden giderek
                  Ekim 2019 yılında işimden ayrıldım.
                </p>
                <p>
                  Mutlu çocuk, mutlu birey tanımını yeniden tasarlayarak 15 Şubat 2020’de TotiliPotili’yi kurdum. Çocuğunuzun, mutlu bir çocuk ve büyüdüğünde de mutlu bir yetişkin olmasını
                  istiyorsanız yapmanız gereken şey; O’nu küçük yaştan itibaren farklı deneyimlere maruz bırakmaktır.
                </p>
                <p>
                  Farklı deneyimlerle neyi sevdiğini, neye ilgi duyduğunu keşfeden çocuk ileride ne yapmak isteyeceğine ya da ne yapmak istemeyeceğine küçük yaştan itibaren karar verebilir. Her zaman
                  değer katmak isteyeceği ve hep sonraki adımını merak edeceği bir ilgi alanı olan çocuğa Dünya’nın en mutlu çocuğudur diyebilir miyiz? Bence diyebiliriz. İşte tam da bu noktada
                  TotiliPotili siz ebeveynlere çocuğunuza sunabileceğiniz alternatifler konusunda yardımcı oluyor. TotiliPotili ile binlerce etkinlik arasından çocuğunuzun yaşına, ilgisine ve aile
                  bütçenize uygun aramayı kolayca yapabileceksiniz.
                </p>
                <br />
                <ul>
                  <li>İletişim içinde olduğumuz herkesle <b>sıcak ve samimi bir bağ kurarız</b>.</li>
                  <li><b>Alternatifler</b> arasından sizin için uygun olan seçeneğe hızlıca ulaşmanızı sağlarız. </li>
                  <li>Söz konusu
                    çocuklarımız olduğu için her adımda <b>titiz ve hassas</b> davranırız.</li>
                </ul>

              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default About;
