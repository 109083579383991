'use client'
import { useEffect, useRef, useState } from 'react'
import Breadcrumb from '../components/Breadcrumb'
import BlogCard from '../components/BlogCard'
import Input from '../components/Input'
import moment from 'moment'
import { postPayment, getEventById, makePayment, checkPaymentStatus, getDiscount } from '../services/api'
import { useCookies } from 'react-cookie'
import Validation from '../components/Validation'
import { PatternFormat } from 'react-number-format'

import PartnershipSuccessModal from '../components/PartnershipSuccessModal'
import { useParams } from 'react-router-dom';

function Payment() {
  const [userNameSurname, setUserNameSurname] = useState('')
  const [modal, setModal] = useState(false)
  const [invoiceNameSurname, setInvoiceNameSurname] = useState('')
  const [invoiceUserIdNumber, setInvoiceUserIdNumber] = useState('')
  const [invoiceCompanyName, setInvoiceCompanyName] = useState('')
  const [invoiceTaxAdministration, setInvoiceTaxAdministration] = useState('')
  const [invoiceTaxNumber, setInvoiceTaxNumber] = useState('')
  const [invoiceCity, setInvoiceCity] = useState('')
  const [invoiceDistrict, setInvoiceDistrict] = useState('')
  const [invoiceAdress, setInvoiceAdress] = useState('')
  const [invoiceId, setInvoiceId] = useState('')
  const [invoiceType, setInvoiceType] = useState(false)
  const [paymentNameSurname, setPaymentNameSurname] = useState('')
  const [paymentCardNumber, setPaymentCardNumber] = useState('')
  const [paymentExpireDate, setPaymentExpireDate] = useState('')
  const [paymentCvc, setPaymentCvc] = useState('')
  const [promotionCode, setPromotionCode] = useState('')
  const [step, setStep] = useState(1)
  const [kvkk, setKvkk] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState(true)
  const [contracts, setContracts] = useState(false)
  const [price, setPrice] = useState(null)
  const [defaultPrice, setDefaultPrice] = useState(null)

  const [data, setData] = useState(null)
  const { id } = useParams();
  const [cookies, setCookie] = useCookies(['userData', 'access_token']);

  const [validationState, setValidationState] = useState(false)
  const [validationText, setValidationText] = useState('')
  const [validationTitle, setValidationTitle] = useState('Hata')
  const [phone, setPhone] = useState('')

  let [count, setCount] = useState(1);

  function incrementCount() {
    if (count >= 10) {
      return
    }
    count = count + 1;
    console.log(price);
    setPrice(count * parseInt(defaultPrice));
    setCount(count);
  }
  function decrementCount() {
    if (count === 1) {
      return
    }
    count = count - 1;
    console.log(price);
    setPrice(count * parseInt(defaultPrice));
    setCount(count);
  }

  useEffect(() => {
    async function getData() {
      await getEventById(id).then((response) => {
        console.log('event detail response', response.response)
        if (response.response !== null) {
          setData(response.response)
          setPrice(response.response.price);
          setDefaultPrice(response.response.price);
        }
      })
    }

    if (data === null && id) {
      getData()
    }
  }, [])

  useEffect(() => {
    if (step === 4) {
      async function getData() {
        await makePayment(
          userNameSurname,
          invoiceNameSurname,
          invoiceAdress,
          invoiceCity,
          invoiceDistrict,
          phone,
          '',
          id,
          price,
          1, // amount
          invoiceUserIdNumber,
          invoiceTaxNumber,
          invoiceTaxAdministration,
          invoiceCompanyName,
          cookies.access_token
        ).then((response) => {
          console.log("payment response", response);
          if (response.response.status === "success") {

            var token = response.response.token;
            if (document.querySelectorAll("#payment-iframe iframe")) {
              document.querySelectorAll("#payment-iframe iframe").forEach(function (iframe) {
                iframe.remove();
              });
            }
            // iframe elementini oluştur
            var iframe = document.createElement('iframe');
            iframe.setAttribute('src', 'https://www.paytr.com/odeme/guvenli/' + token);
            iframe.setAttribute('id', 'paytriframe');
            iframe.setAttribute('frameborder', '0');
            iframe.setAttribute('scrolling', 'no');
            iframe.setAttribute('style', 'width: 100%;');

            // iFrameResize kütüphanesini ekleyerek iframe'i boyutlandır
            var script = document.createElement('script');
            script.setAttribute('src', 'https://www.paytr.com/js/iframeResizer.min.js');

            // Document body'e iframe'i ve script'i ekle
            // document.body.appendChild(iframe);



            document.body.appendChild(script);

            document.getElementById("payment-iframe").append(iframe);
            var script2 = document.createElement('script');
            script2.textContent = 'iFrameResize({}, "#paytriframe");';


            // Script yüklendiğinde çalışacak fonksiyonu tanımla
            script.onload = function () {
              // iFrameResize kütüphanesi yüklendiğinde çalışacak kodu buraya yazın
              document.body.appendChild(script2);
            };
            // State'e iframe'i ekleyin
            // setPaymentForm({ paytriframe: iframe }); 
            //   <script src="https://www.paytr.com/js/iframeResizer.min.js"></script>
            // <iframe src="https://www.paytr.com/odeme/guvenli/" id="paytriframe" frameborder="0"
            // scrolling="no" style="width: 100%;"></iframe>
            // <script>iFrameResize({},'#paytriframe');</script>

            // Cookie Set
            let expires = new Date()
            expires.setTime(expires.getTime() + (60 * 60 * 60 * 1000))
            setCookie('order_id', response.response.order_id, { path: '/', expires })

          } else {
            // Başarısız.
          }
        })
      }
      getData();
    }
  }, [step])

  const userNameSurnameChange = (e: any) => {
    setUserNameSurname(e.target.value)
  }

  const invoiceNameSurnameChange = (e: any) => {
    setInvoiceNameSurname(e.target.value)
  }

  const invoiceCityChange = (e: any) => {
    setInvoiceCity(e.target.value)
  }

  const invoiceDistrictChange = (e: any) => {
    setInvoiceDistrict(e.target.value)
  }

  const invoiceAdressChange = (e: any) => {
    setInvoiceAdress(e.target.value)
  }

  const invoiceIdChange = (e: any) => {
    setInvoiceId(e.target.value)
  }

  const invoiceUserIdNumberChange = (e: any) => {
    setInvoiceUserIdNumber(e.target.value)
  }

  const invoiceTaxAdministrationChange = (e: any) => {
    setInvoiceTaxAdministration(e.target.value)
  }

  const invoiceTaxNumberChange = (e: any) => {
    setInvoiceTaxNumber(e.target.value)
  }

  const invoiceCompanyNameChange = (e: any) => {
    setInvoiceCompanyName(e.target.value)
  }

  const paymentNameSurnameChange = (e: any) => {
    setPaymentNameSurname(e.target.value)
  }

  const paymentCardNumberChange = (e: any) => {
    setPaymentCardNumber(e.target.value)
  }

  const paymentExpireDateChange = (e: any) => {
    setPaymentExpireDate(e.target.value)
  }

  const paymentCvcChange = (e: any) => {
    setPaymentCvc(e.target.value)
  }

  const promotionCodeChange = (e: any) => {
    setPromotionCode(e.target.value)
  }

  const kvkkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKvkk(e.target.checked)
  }

  const contractsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setContracts(e.target.checked)
  }

  const incoiceTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInvoiceType(e.target.checked)
  }

  const paymentMethodChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentMethod(e.target.checked)
  }

  const phoneChange = (e: any) => {
    setPhone(e.target.value)
  }

  // const formSubmit = (e: any) => {
  //   e.preventDefault()
  //   console.log('Deneme')
  //   async function getData() {
  //     // todo : eventId bağlanıcak
  //     await postPayment(
  //       userNameSurname,
  //       invoiceNameSurname,
  //       invoiceCity,
  //       invoiceDistrict,
  //       invoiceAdress,
  //       invoiceId,
  //       paymentNameSurname,
  //       paymentCardNumber,
  //       paymentExpireDate,
  //       paymentExpireDate,
  //       17,
  //     ).then((response) => {
  //       console.log('response', response)
  //     })
  //   }
  //   getData()
  // }

  const paymentButtonEvent = () => {


    if (step === 1) {
      if (userNameSurname === '') {
        setValidationText('Lütfen adınızı ve soyadınızı giriniz!');
        setValidationState(true)
        return false;
      }
      if (phone === '') {
        setValidationText('Lütfen telefon numaranızı giriniz!');
        setValidationState(true)
        return false;
      }

      step < 4 && setStep(step + 1)
    } else if (step === 2) {

      if (selectedOption === 'personal' && invoiceNameSurname === '') {
        setValidationText('Lütfen ad soyad giriniz!');
        setValidationState(true)
        return false;
      }

      if (selectedOption === 'personal' && invoiceUserIdNumber === '') {
        setValidationText('Lütfen TC Kimlik numaranızı giriniz!');
        setValidationState(true)
        return false;
      }

      if (selectedOption === 'corporate' && invoiceCompanyName === '') {
        setValidationText('Lütfen şirket adınızı giriniz!');
        setValidationState(true)
        return false;
      }

      if (selectedOption === 'corporate' && invoiceTaxAdministration === '') {
        setValidationText('Lütfen vergi dairenizi giriniz!');
        setValidationState(true)
        return false;
      }

      if (selectedOption === 'corporate' && invoiceTaxNumber === '') {
        setValidationText('Lütfen vergi numaranızı giriniz!');
        setValidationState(true)
        return false;
      }

      if (invoiceCity === '') {
        setValidationText('Lütfen şehir giriniz!');
        setValidationState(true)
        return false;
      }

      if (invoiceDistrict === '') {
        setValidationText('Lütfen ilçe giriniz!');
        setValidationState(true)
        return false;
      }

      if (invoiceAdress === '') {
        setValidationText('Lütfen adres giriniz!');
        setValidationState(true)
        return false;
      }


      step < 4 && setStep(step + 1)
    } else if (step === 3) {

      if (promotionCode !== '') {
        // Promosyon kodu kontrolü  
        async function getData() {
          await getDiscount(promotionCode, cookies.access_token).then((response) => {
            console.log('checkPaymentStatus response', response)
            if (response.response) {
              setPrice(price - parseInt(response.response.amount))
              step < 4 && setStep(step + 1)
            } else {
              // Promosyon Hatalı
              setValidationText('Promosyon Kodu Hatalı!')
              setValidationState(true)
              setPrice(defaultPrice);
              return;
            }
          })
        }
        getData()
      }

      if (!kvkk) {
        setValidationText('Lütfen Kvkk metnini onaylayın.');
        setValidationState(true)
        return false;
      }

      if (!contracts) {
        setValidationText('Lütfen gerekli sözleşmeleri onaylayın.');
        setValidationState(true)
        return false;
      }

      step < 4 && setStep(step + 1)
    } else {
      // step < 4 && setStep(step + 1)
    }



  }

  const [selectedOption, setSelectedOption] = useState('personal');

  // Radio input değiştiğinde çağrılacak fonksiyon
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <>
      <main id="payment-main">
        <section className={'section-pattern white reverse'}></section>
        <section className="payment-section">
          <div className="container">
            <div className="main-inner">
              <h1 className="heading-page-title">Sipariş Bilgileri</h1>
              <div className="payment-section-main-inner">
                <div className="main-side">
                  <div className="payment-step-card">
                    <div>
                      <div
                        className={`icon-box active`}
                        onClick={() => {
                          step > 1 && setStep(1)
                        }}>
                        <img src="/assets/images/payment-steps-icons/ticket.svg" alt="..." width={60} height={60} />
                        <img src="/assets/images/payment-steps-icons/ticket-white.svg" alt="..." width={60} height={60} />
                      </div>
                      <span className="primary-title">Katılımcı Bilgisi</span>
                    </div>
                    <div>
                      <div
                        className={`icon-box ${step >= 2 && 'active'}`}
                        onClick={() => {
                          step > 2 && setStep(2)
                        }}>
                        <img src="/assets/images/payment-steps-icons/document.svg" alt="..." width={52} height={52} />
                        <img src="/assets/images/payment-steps-icons/document-white.svg" alt="..." width={52} height={52} />
                      </div>
                      <span className="primary-title">Fatura Bilgisi</span>
                    </div>
                    <div>
                      <div
                        className={`icon-box ${step >= 3 && 'active'}`}
                        onClick={() => {
                          step > 3 && setStep(3)
                        }}>
                        <img src="/assets/images/payment-steps-icons/wallet.svg" alt="..." width={54} height={48} />
                        <img src="/assets/images/payment-steps-icons/wallet-white.svg" alt="..." width={54} height={48} />
                      </div>
                      <span className="primary-title">Ödeme Şekli</span>
                    </div>
                    <div>
                      <div
                        className={`icon-box ${step >= 4 && 'active'}`}
                        onClick={() => {
                          step > 4 && setStep(4)
                        }}>
                        <img src="/assets/images/payment-steps-icons/check.svg" alt="..." width={56} height={40} />
                        <img src="/assets/images/payment-steps-icons/check-white.svg" alt="..." width={56} height={40} />
                      </div>
                      <span className="primary-title">Ödeme</span>
                    </div>
                    <div className="line-huge"></div>
                  </div>
                  <div className="payment-step-main-container">
                    <div className={`payment-step-tab-container active-step-${step}`}>
                      <div>
                        <div className='form-group'>
                          <div className="textbox counter-form">
                            <div>{count}</div>
                            <button onClick={decrementCount}>-</button>
                            <button className='btn btn-primary' onClick={incrementCount}>+</button>
                          </div>
                        </div>
                        <div className="form-group sm-size">
                          <label className="label" htmlFor="user-name-surname">
                            Ad - Soyad:
                          </label>
                          <Input
                            type={'text'}
                            className={'textbox'}
                            id={'user-name-surname'}
                            name={'userNameSurname'}
                            value={userNameSurname ? userNameSurname : ''}
                            onChange={userNameSurnameChange}
                          />
                        </div>
                        <div className="form-group sm-size">
                          <label className="label" htmlFor="phone">
                            Telefon:
                          </label>
                          <PatternFormat format="+90 ### ### ## ##" allowEmptyFormatting mask="_" className={'textbox'} id={'phone'} name={'phone'} value={phone ? phone : ''} onChange={phoneChange} />
                        </div>
                      </div>
                      <div>
                        {/* <label className="switch-box">
                          <Switch
                            checked={checked}
                            onChange={handleChange}
                            onColor="##e34e35"
                            onHandleColor="#e34e35"
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={48}
                            className="react-switch"
                            id="material-switch"
                          />
                          <span>{checked ? 'Kurumsal' : 'Bireysel'}</span>
                        </label> */}
                        <div className='payment-invoice-type-form-group'>
                          <label className='form-group radio invoice-type-form'>
                            <input
                              type="radio"
                              value="personal"
                              checked={selectedOption === 'personal'}
                              onChange={handleOptionChange}
                            />
                            <span className="checkmark"></span>
                            <span>Bireysel</span>
                          </label>
                          <label className='form-group radio invoice-type-form'>
                            <input
                              type="radio"
                              value="corporate"
                              checked={selectedOption === 'corporate'}
                              onChange={handleOptionChange}
                            />
                            <span className="checkmark"></span>
                            <span>Kurumsal</span>
                          </label>
                        </div>

                        {/* Bireysel Alan */}
                        {selectedOption === 'personal' && (
                          <>
                            <div className="form-group sm-size">
                              <label className="label" htmlFor="invoice-name-surname">
                                Ad - Soyad:
                              </label>
                              <Input
                                type={'text'}
                                className={'textbox'}
                                id={'invoice-name-surname'}
                                name={'invoiceNameSurname'}
                                value={invoiceNameSurname ? invoiceNameSurname : ''}
                                onChange={invoiceNameSurnameChange}
                              />
                            </div>
                            <div className="form-group sm-size">
                              <label className="label" htmlFor="invoice-user-identification-number">
                                TC Kimlik No:
                              </label>
                              <Input
                                type={'text'}
                                className={'textbox'}
                                maxLenght={11}
                                id={'invoice-user-identification-number'}
                                name={'invoiceUserIdentificationNumber'}
                                value={invoiceUserIdNumber ? invoiceUserIdNumber : ''}
                                onChange={invoiceUserIdNumberChange}
                              />
                            </div>
                          </>
                        )}

                        {/* Kurumsal Alan */}
                        {selectedOption === 'corporate' && (
                          <>
                            <div className="form-group sm-size">
                              <label className="label" htmlFor="invoice-company-name">
                                Firma Adı:
                              </label>
                              <Input
                                type={'text'}
                                className={'textbox'}
                                id={'invoice-company-name'}
                                name={'invoiceCompanyName'}
                                value={invoiceCompanyName ? invoiceCompanyName : ''}
                                onChange={invoiceCompanyNameChange}
                              />
                            </div>
                            <div className="form-group-double">
                              <div className="form-group sm-size">
                                <label className="label" htmlFor="invoice-tax-administration">
                                  Vergi Dairesi:
                                </label>
                                <Input
                                  type={'text'}
                                  className={'textbox'}
                                  id={'invoice-tax-administration'}
                                  name={'invoiceTaxAdministration'}
                                  value={invoiceTaxAdministration ? invoiceTaxAdministration : ''}
                                  onChange={invoiceTaxAdministrationChange}
                                />
                              </div>
                              <div className="form-group sm-size">
                                <label className="label" htmlFor="invoice-tax-number">
                                  Vergi No:
                                </label>
                                <Input
                                  type={'text'}
                                  className={'textbox'}
                                  id={'invoice-tax-number'}
                                  name={'invoiceTaxNumber'}
                                  value={invoiceTaxNumber ? invoiceTaxNumber : ''}
                                  onChange={invoiceTaxNumberChange}
                                />
                              </div>
                            </div>
                          </>
                        )}

                        <span className="step-secondary-title">Adres Bilgisi:</span>
                        <div className="form-group-double">
                          <div className="form-group sm-size">
                            <label className="label" htmlFor="invoice-city">
                              İl:
                            </label>
                            <Input type={'text'} className={'textbox'} id={'invoice-city'} name={'invoiceCity'} value={invoiceCity ? invoiceCity : ''} onChange={invoiceCityChange} />
                          </div>
                          <div className="form-group sm-size">
                            <label className="label" htmlFor="invoice-district">
                              İlçe:
                            </label>
                            <Input
                              type={'text'}
                              className={'textbox'}
                              id={'invoice-district'}
                              name={'invoiceDistrict'}
                              value={invoiceDistrict ? invoiceDistrict : ''}
                              onChange={invoiceDistrictChange}
                            />
                          </div>
                        </div>
                        <div className="form-group sm-size">
                          <label className="label" htmlFor="invoice-adress">
                            Açık Adres:
                          </label>
                          <Input type={'text'} className={'textbox'} id={'invoice-adress'} name={'invoiceAdress'} value={invoiceAdress ? invoiceAdress : ''} onChange={invoiceAdressChange} />
                        </div>
                      </div>
                      <div>
                        <label htmlFor={`payment-method`} className="form-group checkbox full">
                          <Input type={'checkbox'} id={`payment-method`} name="payment-method" checked={!!paymentMethod} onChange={paymentMethodChange} />
                          <span>Kredi/Banka Kartı</span>
                        </label>

                        <div className="form-group sm-size">
                          <label className="label" htmlFor="promotion-code">
                            Promosyon Kodu
                          </label>
                          <Input type={'text'} className={'textbox'} id={'promotion-code'} name={'promotionCode'} value={promotionCode ? promotionCode : ''} onChange={promotionCodeChange} />
                        </div>
                        <label htmlFor={`kvkk`} className="form-group checkbox full">
                          <Input type={'checkbox'} id={`kvkk`} name="kvkk" checked={!!kvkk} onChange={kvkkChange} />
                          <span>
                            Kampanyalardan ve bildirimlerden haberdar olmak için, <a target='_blank' href={'/page/gizlilik-sozlesmesi-kvkk'}>Gizlilik ve KVKK Metni</a> kapsamında elektronik ileti almak istiyorum.
                          </span>
                        </label>
                        <label htmlFor={`contracts`} className="form-group checkbox full">
                          <Input type={'checkbox'} id={`contracts`} name="contracts" checked={!!contracts} onChange={contractsChange} />
                          <span>
                            <a target='_blank' href={'/page/on-bilgilendirme-metni'}>Ön Bilgilendirme Metni</a>&#39;ni okuduğumu, <a target='_blank' href={'/page/kullanici-sozlesmesi'}>Kullanıcı Sözleşmesi</a>&#39;ni, <a target='_blank' href={'/page/mesafeli-satis-sozlesmesi'}>Mesafeli Satış Sözleşmesi</a> ve
                            &nbsp;
                            <a target='_blank' href={'/page/iptal-iade-degisim-kosullari'}>İptal, iade ve Değişim Koşulları</a>&#39;nı kabul ettiğimi onaylıyorum.
                          </span>
                        </label>
                      </div>
                      <div>

                        <div id="payment-iframe"></div>

                        {/* <div className="form-group sm-size">
                          <label className="label" htmlFor="payment-name-surname">
                            Kart Üzerindeki Ad-Soyad:
                          </label>
                          <Input
                            type={'text'}
                            className={'textbox'}
                            id={'payment-name-surname'}
                            name={'paymentNameSurname'}
                            value={paymentNameSurname ? paymentNameSurname : ''}
                            onChange={paymentNameSurnameChange}
                          />
                        </div>
                        <div className="form-group sm-size">
                          <label className="label" htmlFor="payment-card-number">
                            Kart No:
                          </label>
                          <Input
                            type={'text'}
                            className={'textbox'}
                            id={'payment-card-number'}
                            name={'paymentCardNumber'}
                            value={paymentCardNumber ? paymentCardNumber : ''}
                            onChange={paymentCardNumberChange}
                          />
                        </div>
                        <div className="form-group-double">
                          <div className="form-group sm-size">
                            <label className="label" htmlFor="payment-expire-date">
                              SKT:
                            </label>
                            <Input
                              type={'text'}
                              className={'textbox'}
                              id={'payment-expire-date'}
                              name={'paymentExpireDate'}
                              value={paymentExpireDate ? paymentExpireDate : ''}
                              onChange={paymentExpireDateChange}
                            />
                          </div>
                          <div className="form-group sm-size">
                            <label className="label" htmlFor="payment-cvc">
                              CVC:
                            </label>
                            <Input type={'text'} className={'textbox'} id={'payment-cvc'} name={'paymentCvc'} value={paymentCvc ? paymentCvc : ''} onChange={paymentCvcChange} />
                          </div>
                        </div> */}
                      </div>
                    </div>
                    <div className="form-group btn-group">
                      <button
                        type="submit"
                        className="btn btn-rounded btn-orange"
                        onClick={() => {
                          paymentButtonEvent()
                        }}>
                        {step === 4 ? 'Satın Al' : 'Devam Et'}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="payment-sidebar">
                  {data && data.category === "Atölye" && (
                    <div className="event-detail-sidebar">
                      <span className="primary-title">
                        {moment(data.event_date).format('D')} {moment(data.event_date).format('MMMM')} {moment(data.event_date).format('Y')}
                      </span>
                      <div className="sidebar-body">
                        <div>
                          <img src="/assets/images/icon/clock-white.svg" alt="..." width={20} height={20} />
                          <span>{data.start_time} - {data.end_time}</span>
                        </div>
                        <div>
                          <span>
                            {data.county}, {data.city}
                          </span>
                        </div>
                        {data.age_interval && (
                          <div>
                            <span>
                              Yaş Grubu: {data.age_interval}
                            </span>
                          </div>
                        )}
                        {data.capacity && (
                          <div>
                            <span>
                              Kontenjan: {data.capacity}
                            </span>
                          </div>
                        )}

                      </div>
                      <div className="btn-group">
                        <span className="price-badge">{price && price} ₺</span>
                      </div>
                    </div>

                  )}

                  {data && data.category === "Kamp" && (
                    <div className="event-detail-sidebar">
                      <span className="secondary-title">
                        Başlangıç Tarihi: {moment(data.start_date).format('D')} {moment(data.start_date).format('MMMM')} {moment(data.start_date).format('Y')}
                      </span>
                      <span className="secondary-title">
                        Bitiş Tarihi: {moment(data.end_date).format('D')} {moment(data.end_date).format('MMMM')} {moment(data.end_date).format('Y')}
                      </span>
                      <div className="sidebar-body border-top-padding">
                        <div>
                          <span>
                            {data.county}, {data.city}
                          </span>
                        </div>
                        {data.facility && (
                          <div>
                            <span>
                              Tesis: {data.facility}
                            </span>
                          </div>
                        )}
                        {data.age_interval && (
                          <div>
                            <span>
                              Yaş Grubu: {data.age_interval}
                            </span>
                          </div>
                        )}
                        {data.capacity && (
                          <div>
                            <span>
                              Kontenjan: {data.capacity}
                            </span>
                          </div>
                        )}

                      </div>
                      <div className="btn-group">
                        <span className="price-badge">{price && price} ₺</span>
                      </div>
                    </div>

                  )}

                  {data && data.category === "Kurs" && (
                    <div className="event-detail-sidebar">
                      <span className="secondary-title">
                        Başlangıç Tarihi: {moment(data.start_date).format('D')} {moment(data.start_date).format('MMMM')} {moment(data.start_date).format('Y')}
                      </span>
                      <span className="secondary-title">
                        Bitiş Tarihi: {moment(data.end_date).format('D')} {moment(data.end_date).format('MMMM')} {moment(data.end_date).format('Y')}
                      </span>
                      <div className="sidebar-body border-top-padding">
                        {(data.start_time && data.end_time) && (
                          <div>
                            <img src="/assets/images/icon/clock-white.svg" alt="..." width={20} height={20} />
                            <span>{data.start_time} - {data.end_time}</span>
                          </div>
                        )}
                        <div>
                          <span>
                            {data.county}, {data.city}
                          </span>
                        </div>
                        {data.age_interval && (
                          <div>
                            <span>
                              Yaş Grubu: {data.age_interval}
                            </span>
                          </div>
                        )}
                        {data.capacity && (
                          <div>
                            <span>
                              Kontenjan: {data.capacity}
                            </span>
                          </div>
                        )}

                      </div>
                      <div className="btn-group">
                        <span className="price-badge">{price && price} ₺</span>
                      </div>
                    </div>

                  )}

                  {data && data.category === "Tiyatro" && (
                    <div className="event-detail-sidebar">
                      <span className="primary-title">
                        {moment(data.event_date).format('D')} {moment(data.event_date).format('MMMM')} {moment(data.event_date).format('Y')}
                      </span>
                      <div className="sidebar-body">
                        <div>
                          <img src="/assets/images/icon/clock-white.svg" alt="..." width={20} height={20} />
                          <span>{data.start_time} - {data.end_time}</span>
                        </div>
                        <div>
                          <span>
                            {data.county}, {data.city}
                          </span>
                        </div>
                        {data.age_interval && (
                          <div>
                            <span>
                              Yaş Grubu: {data.age_interval}
                            </span>
                          </div>
                        )}
                        {data.capacity && (
                          <div>
                            <span>
                              Kontenjan: {data.capacity}
                            </span>
                          </div>
                        )}
                        {data.stage && (
                          <div>
                            <span>
                              Sahne: {data.stage}
                            </span>
                          </div>
                        )}

                      </div>
                      <div className="btn-group">
                        <span className="price-badge">{price && price} ₺</span>
                      </div>
                    </div>

                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      {modal && <PartnershipSuccessModal setEvent={setModal} />}
      {validationState && <Validation setEvent={setValidationState} text={validationText} title={validationTitle} />}
    </>
  )
}

export default Payment
