import { getBlogById } from '../services/api'
import { useState, useEffect, useLayoutEffect } from 'react' 
import { useParams } from 'react-router-dom';
 
function BlogDetail() {
    const { id } = useParams();
  const [data, setData] = useState(null)

  useEffect(() => { 
    async function getData() {
      await getBlogById(id).then((response) => {
        console.log('blog detail response', response)
        if (response?.response !== null) {
          setData(response.response)
        }
      })
    }

    if (data === null) {
      getData()
    }
  }, [])
  return ( 
      <main id="blog-detail-main">
        <section className={'section-pattern white reverse'}></section>
        <section className="section-blog-title">
          <div className="container">
            <div className="main-inner">
              <span className="primary-badge">Blog</span>
              <div>
                <h1 className="primary-title">{data?.title}</h1>
                <span className="desc">
                  <b>{data?.category}</b>
                </span>
              </div>
            </div>
          </div>
        </section>
        <section className="section-blog-detail">
          <div className="container">
            <div className="full-image-box">
              <img src={data?.image} alt="..." width={350} height={250} />
            </div>
            <div className="editor-content" dangerouslySetInnerHTML={{ __html: data?.blog_text }} >
            
            </div>
          </div>
        </section>
      </main> 
  )
}

export default BlogDetail
