'use client' 
import React, { useLayoutEffect, useState } from 'react'
// import Swiper core and required modules
import { Pagination, A11y } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import { getEvents } from '../services/api'

const breakpoints = {
  // ekrana göre özelleştirebilirsiniz
  0: {
    slidesPerView: 1,
    spaceBetween: 10,
  },
  992: {
    slidesPerView: 3,
    spaceBetween: 10,
  },
}

export default function Events() {
  const [data, setData] = useState([])

  useLayoutEffect(() => {
    async function getData() {
      await getEvents(1, 6).then((response) => {
        console.log('events response', response.response.paginatedEvents)
        setData(response.response.paginatedEvents)
      })
    }
    getData()
  }, [])

  return (
    <>
      <section className={'section-pattern blue'}></section>
      <section id={'events-section'} className="section p-xs section-blue">
        <h1 className="section-title">Popüler Aktiviteler</h1>
        <div className="section-container">
          <Swiper
            className="swiper-has-box-shadow"
            modules={[Pagination, A11y]}
            breakpoints={breakpoints}
            //onSwiper={(swiper) => console.log(swiper)}
            //onSlideChange={() => console.log('slide change')}
          >
            {data &&
              data.map((item) => (
                <SwiperSlide key={item.id}>
                  <div className="blog-card">
                    <a href={'/event/'+item.id} className="img">
                      <img src={item.image} alt="..." width={350} height={250} />
                      <span className="badge">{item.price}&nbsp;TL</span>
                    </a>
                    <div className="detail">
                      <span className="location">
                        <img src="/assets/images/icon/pin.svg" alt="..." width={10} height={14} />
                        <span>{item.city}</span>
                      </span>
                      <a href={'/event/'+ item.id}>
                        <h3 className="primary-title">{item.name}</h3>
                      </a>
                      <span className="bottom-huge">
                        <span className="info">{item.age_interval}</span>
                        <a href={'/event/'+item.id} className="btn btn-rounded btn-orange">
                          Etkinliğe Katıl
                          <img src="/assets/images/icon/long-right-arrow.svg" alt="..." width={13} height={10} />
                        </a>
                      </span>
                    </div>
                  </div>
                </SwiperSlide>
              ))} 
          </Swiper>
        </div>
      </section>
      <section className={'section-pattern blue reverse'}></section>
    </>
  )
}
