'use client'
import React from 'react'
import CustomSelect from 'react-select'
import { CSSProperties } from 'react'

const dot = (color = 'transparent') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
})

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}
const groupBadgeStyles: CSSProperties = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
}

export default function Select({ options: Options = { value: '', label: '' }, onChange , disabled, isMulti = false}) {
  return <CustomSelect placeholder="Seçiniz" isMulti={isMulti} options={Options} classNamePrefix="select" onChange={onChange} disabled={true} />
}
