import { useState, useEffect, useLayoutEffect } from 'react';
import Breadcrumb from '../components/Breadcrumb';
import { useParams } from 'react-router-dom';
import EventCard from '../components/EventCard';
import axios from 'axios';
import Select from '../components/Select';
import Loader from '../components/loader';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

import { checkPaymentStatus } from '../services/api';
// http://192.168.1.109:3000/events?page=1&pageSize=15
import { useCookies } from 'react-cookie';

function PaymentResponse() {
  const [status, setStatus] = useState(false);
  const [loaderStatus, setLoaderStatus] = useState(true);
  const [cookies] = useCookies(['userData', 'access_token', 'order_id']);

  useEffect(() => {
    let requestLimit = 8;
    const x = setInterval(() => {
        if(!cookies.order_id){
            alert("Sipariş Oluşturmanı gerektirir.");
            window.location.href = "/";
            return false
        }
        if(requestLimit <= 0){
            window.location.href = "/";
            return false
        }

      async function getData() {
        requestLimit --;
        await checkPaymentStatus(cookies.order_id, cookies.access_token).then((response) => {
          console.log('response', response); 
          if(response?.response === 'SUCCESS'){
            setLoaderStatus(false)
            setStatus(true)
            clearInterval(x);  
          }else if(response?.response === 'CREATED'){

          } else{
            setLoaderStatus(false)
            setStatus(false)
            clearInterval(x);
          }
        });
      }
      getData(); 
    }, 5000);
  }, []);

  return (
    <main id="event-list-main">
      <section className={'section-pattern white reverse'}></section>
      <section className="payment-response-page-section">
        <div className="container">
          <div className="main-inner">
            {loaderStatus && <Loader/>}
            {status && (
              <>
                <div className="image">
                  <img src="/assets/images/icon/check-circle.svg" alt="..." width={13} height={10} />
                </div>
                <span className="heading-3">Ödeme Başarılı</span>
                <div className="editor-content">
                  <p>Ödeme işlemi başarıyla tamamlandı. Teşekkür ederiz! Ödemeniz alınmıştır ve işleminiz başarıyla işlenmiştir. </p>
                </div>
              </>
            )}

            {!status && (
              <>
                <div className="image close">
                  <img src="/assets/images/icon/close-white.svg" alt="..." width={13} height={10} />
                </div>
                <span className="heading-3">Ödeme Başarısız!</span>
                <div className="editor-content">
                  <p>Ödeme işlemi başarıyla tamamlandı. Teşekkür ederiz! Ödemeniz alınmıştır ve işleminiz başarıyla işlenmiştir. </p>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </main>
  );
}

export default PaymentResponse;
