
function Page() {
  return (
    <>
      <main id="blog-detail-main">
        <section className={'section-pattern white reverse'}></section>
        <section className="section-page-title">
          <div className="container">
            <div className="main-inner">
              <div>
                <h1 className="primary-title">İPTAL, İADE VE DEĞİŞİM KOŞULLARI</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="section-blog-detail">
          <div className="container">
            <div className="editor-content">
              <p>
                1. GÜLŞAH AZAKLI GÜNAY’a ait işbu “www.totilipotili.com” alan adlı web sitesi (“Web Sitesi”)’ni veya ‘‘totilipotili aplikasyonunu’’ (‘‘Aplikasyon’’) ziyaret eden (“Kullanıcı”) ve
                Site’ye üye olan kişiler (“Üye”), Site veya Aplikasyon üzerinden, elektronik ortamda sipariş verdiği takdirde (“Müşteri”) Web Sitesi’nde veya Aplikasyon’da sunulan Mesafeli Satış
                Sözleşmesi’ni, Gizlilik Sözleşmesi’ni, Kullanıcı Sözleşmesi’ni ve Ön Bilgilendirme Metni’ni kabul etmiş sayılır.
              </p>

              <p>
                2. Web Sitesi’nde veya Aplikasyon’da, Gülşah Azaklı Günay ile iş ortaklığı kurmak amaçlı sözleşme akdederek aktivite satışı yapan iş ortakları “İş Ortağı” sıfatıyla, satışı
                gerçekleştirilen hizmet bedellerini, www.totilipotili.com’un ve totilipotili aplikasyonu’nun hizmet alt yapısını kullanarak tahsil eder. Hizmetler, web sitesi veya aplikasyon üzerinden
                gerçekleştirilir.
              </p>

              <p>
                3. Müşteri, web sitesinde veya aplikasyonda yer alan sözleşmelerin akdedildikleri tarihten itibaren yedi gün içerisinde herhangi bir gerekçe göstermeksizin sözleşmeden cayma hakkına
                sahiptir. Müşterinin cayma hakkını kullanabilmesi için satın almış olduğu hizmeti hiçbir şekilde kullanmamış olması ve/veya cayma hakkı süresi olan 7 günlük süreyi aşmamak kaydıyla
                etkinlik seansının başlamasına 5 günden fazla zaman kalması gerekmektedir, aksi halde cayma hakkı geçersiz olacaktır. Gününde ve saatinde kullanılmayan etkinlik biletleri geçersiz
                olup, hizmet bedeli iadesi ve/veya değişikliği yapılması mümkün değildir.
              </p>
              <p>
                4. Müşterinin cayma hakkını süresi içerisinde ve usulüne uygun kullanması halinde www.totilipotili.com ve totilipotili aplikasyonu cayma hakkı bildiriminin kendisine ulaştığı tarihten
                itibaren en geç on dört gün içerisinde almış olduğu bilete ilişkin bedeli Müşteriye iade edecektir. Müşteri, cayma hakkı kullanımını yazılı olarak yapacak veya www.totilipotili.com’un
                ve totilipotili aplikasyonu’nun belirtilen iletişim formuna veya e-posta adresine bildirerek gerçekleştirecektir.
              </p>
              <p>
                5. Müşteri’nin ya da İş Ortağı’nın, İş Ortağı’nın aktivitelere dair yayımladığı İptal/İade Politikası’na uymaması durumunda, Gülşah Azaklı Günay’ın herhangi bir sorumluluğu
                bulunmamaktadır.
              </p>

              <p>6. Müşteri, satın aldığı hizmet bedelini ödemez veya banka kayıtlarında iptal ederse, İş Ortağı’nın hizmeti gerçekleştirme yükümlülüğü sona erer.</p>

              <p>
                7. Müşteri’ye ait kredi kartının yetkisiz kişilerce haksız kullanılması sonucunda, bedelinin ilgili banka veya finans kuruluşu tarafından Satıcı’ya ödenmemesi halinde, Müşteri hizmetin
                ifasından yararlanamayacaktır.
              </p>

              <p>
                8. Satıcı, tarafların iradesi dışında gelişen, önceden tahmin edilemeyen ve tarafların borçlarını yerine getirmesini engelleyici ve/veya geciktirici mücbir sebeplerin oluşması gibi
                haller nedeniyle ifa edilecek hizmeti süresi içinde ifa edemez ise, durumu Müşteri’ye mümkün olan en kısa sürede bildireceğini kabul, beyan ve taahhüt eder. Böyle bir durumda, Müşteri,
                satışın iptal edilmesini ya da hizmetin varsa benzeri ile değiştirilmesini, mücbir sebeplerden kaynaklanan durumun ortadan kalkmasına kadar ertelenmesini, Satıcı’dan talep etme hakkını
                haizdir.
              </p>

              <p>
                9. Müşteri, kredi kartı ile ödediği hizmet bedeli konusunda temerrüde düştüğü takdirde, kart sahibi banka ile arasındaki kredi kartı sözleşmesi çerçevesinde faiz ödeyeceğini ve bankaya
                karşı sorumlu olacağını kabul, beyan ve taahhüt eder. Bu durumda, ilgili banka hukuki yollara başvurabilir; doğacak masrafları ve vekalet ücretini Müşteri’den talep edebilir ve her
                koşulda Müşteri’nin borcundan dolayı temerrüde düşmesi halinde, Müşteri, borcun gecikmeli ifasından dolayı Gülşah Azaklı Günay’ın ve/veya Satıcı’nın uğradığı zarar ve ziyanını
                ödeyeceğini kabul, beyan ve taahhüt eder.
              </p>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}

export default Page
