import { useEffect,useLayoutEffect, useRef, useState } from 'react';
import moment from 'moment';  
import { useCookies } from 'react-cookie'
import 'moment/locale/tr'  // without this line it didn't work
import ChangePasswordModal from '../components/ChangePasswordModal' 
import { getOrders } from '../services/api'  
import EventCard from "../components/EventCard"
moment.locale('tr'); 
function Profile() { 
  const [modal, setModal] = useState(false)
  const [cookies] = useCookies(['userData','access_token']);
  const [data, setData] = useState([])
  const [startDate, setStartDate] = useState(new Date());
 


  useEffect(() => {
    async function getData() {
      await getOrders(1, 100,cookies.access_token).then((response) => {
       console.log(response.response)
       setData(response.response)
      })
    }
    getData()
  }, [])
 

  return (
    <>
      <main id="profile-main">
        <section className={'section-pattern white reverse'}></section>
        <section className="profile-section pb-0">
          <div className="container">
            <div className="main-inner">
              <h1 className="heading-page-title">Biletlerim</h1>
            </div>
          </div> 
        </section>
        <section className="event-list-section pt-0">
          <div className="container">
            <div className="event-list-section-main">
              <div className="list-container">{data && data.map((item) => <EventCard key={item.id} data={item} />)}</div>
            </div>
          </div>
        </section>
      </main>
      {modal && <ChangePasswordModal setEvent={setModal} />}
    </>
  )
}

export default Profile
