import React from "react";
import { Suspense, useState, useTransition } from 'react'; 
import 'react-app-polyfill/stable';// ref: https://github.com/facebook/create-react-app/blob/main/packages/react-app-polyfill/README.md
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './assets/styles/index.scss';  
// Layout  
// import RequireAuth from "./layouts/requireAuth";
// Context
import SiteProvider from './context/SiteContext';  

import Home from './pages/home.js'; 
import About from './pages/about.js'; 
import Blog from './pages/blog.js';
import BlogCategory from './pages/blogCategory.js';
import BlogDetail from './pages/BlogDetail.js';
import Events from './pages/events.js';
import EventDetail from './pages/eventDetail.js';
import Partnership from './pages/partnership.js';
import SignUp from './pages/SignUp.js';
import Company from './pages/Company.js';
import Profile from './pages/profile.js';
import ProfileEvents from './pages/profile-events.js';
import Page from './pages/page.js';
import Faq from './pages/faq.js';
import PageGizlilikSozlesmesi from './pages/gizlilik-sozlesmesi-kvkk.js';
import PageIptalIadeKosullari from './pages/iptal-iade-degisim-kosullari.js';
import PageKullaciniciSozlesmesi from './pages/kullanici-sozlesmesi.js';
import PageMesafeliSatisSozlesmesi from './pages/mesafeli-satis-sozlesmesi.js';
import PageOnBilgilendirmeMetrni from './pages/on-bilgilendirme-metni.js';
import Payment from './pages/payment.js';
import VerifyAccount from './pages/verify-account.js';
import PaymentResponse from './pages/payment-response.js';
import AraciKomisyonSozlesmesi from './pages/araci-komisyon-sozlesmesi.js';


import Layout from './Layout.js';
 

ReactDOM.createRoot(
  document.getElementById("root")).render(
    <SiteProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout><Home /></Layout>} /> 
          <Route path="/about" element={<Layout><About /></Layout>} /> 
          <Route path="/faq" element={<Layout><Faq /></Layout>} /> 
          <Route path="/blog" element={<Layout><Blog /></Layout>} /> 
          <Route path="/blogs/category/:link" element={<Layout><BlogCategory /></Layout>} /> 
          <Route path="/blog/:id" element={<Layout><BlogDetail /></Layout>} /> 

          <Route path="/events" element={<Layout><Events /></Layout>} />
          <Route path="/events/:category" element={<Layout><Events /></Layout>} />
          <Route path="/event/:id" element={<Layout><EventDetail /></Layout>} />
          <Route path="/partnership" element={<Layout><Partnership /></Layout>} />
          <Route path="/sign-up" element={<Layout><SignUp /></Layout>} />
          <Route path="/company/:id" element={<Layout><Company /></Layout>} />
          <Route path="/profile" element={<Layout><Profile /></Layout>} />
          <Route path="/profile-events" element={<Layout><ProfileEvents /></Layout>} />
          <Route path="/payment/:id" element={<Layout><Payment /></Layout>} />
          <Route path="/verify/:code" element={<Layout><VerifyAccount /></Layout>} />
          <Route path="/payment-response" element={<Layout><PaymentResponse /></Layout>} />

          {/* <Route path="/page/:link" element={<Layout><Page /></Layout>} /> */}
          <Route path="page/gizlilik-sozlesmesi-kvkk" element={<Layout><PageGizlilikSozlesmesi /></Layout>} />
          <Route path="page/iptal-iade-degisim-kosullari" element={<Layout><PageIptalIadeKosullari /></Layout>} />
          <Route path="page/kullanici-sozlesmesi" element={<Layout><PageKullaciniciSozlesmesi /></Layout>} />
          <Route path="page/mesafeli-satis-sozlesmesi" element={<Layout><PageMesafeliSatisSozlesmesi /></Layout>} />
          <Route path="page/on-bilgilendirme-metni" element={<Layout><PageOnBilgilendirmeMetrni /></Layout>} />
          <Route path="page/araci-komisyon-sozlesmesi" element={<Layout><AraciKomisyonSozlesmesi /></Layout>} />

          {/* <Route path="/" element={
            <RequireAuth>
              <Layout>
                <Home />
              </Layout>
            </RequireAuth>
          } /> */}

          {/* <Route path="title/detail/:id" element={
            <RequireAuth>
              <TableProvider><TitleDetail /></TableProvider>
            </RequireAuth>
          } />

          <Route path="title/single-detail" element={
            <RequireAuth>
              <TableProvider><SingleTitleDetail /></TableProvider>
            </RequireAuth>} />

          <Route path="season/detail" element={
            <RequireAuth>
              <SeasonDetail />
            </RequireAuth>
          } />


          <Route path="video" element={
            <RequireAuth>
              <TableProvider><VideoList /></TableProvider>
            </RequireAuth>
          } />

          <Route path="video/list" element={
            <RequireAuth>
              <TableProvider><VideoList /></TableProvider>
            </RequireAuth>
          } />

          <Route path="video/detail/:id" element={
            <RequireAuth>
              <VideoDetail />
            </RequireAuth>} />

          <Route path="playlist/list" element={
            <RequireAuth>
              <TableProvider><PlaylistSidebar /><Playlist /></TableProvider>
            </RequireAuth>
          } />

          <Route path="playlist/list/:id" element={
            <RequireAuth>
              <TableProvider><PlaylistSidebar /><Playlist /></TableProvider>
            </RequireAuth>} />

          <Route path="playlist/detail/dynamic/:id" element={
            <RequireAuth>
              <TableProvider><PlaylistDetailDynamic /></TableProvider>
            </RequireAuth>
          } />

          <Route path="playlist/detail/manuel/:id" element={
            <RequireAuth>
              <TableProvider><PlaylistDetailManuel /></TableProvider>
            </RequireAuth>
          } />

          <Route path="settings/users" element={
            <RequireAuth>
              <TableProvider>
                <Users />
              </TableProvider>
            </RequireAuth>
          } />

          <Route path="settings/user/add" element={
            <RequireAuth>
              <UserAdd />
            </RequireAuth>
          } />

          <Route path="settings/user/detail/:id" element={
            <RequireAuth>
              <UserDetail />
            </RequireAuth>
          } />

          <Route path="settings/categories" element={
            <RequireAuth>
              <SettingsCategories />
            </RequireAuth>} /> */}

        </Routes>
      </BrowserRouter>
    </SiteProvider>
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
