'use client'
// import Image from 'next/image'
import React, { useLayoutEffect, useState, useEffect } from 'react'
// import Swiper core and required modules
import { Pagination, A11y } from 'swiper/modules'

import { Swiper, SwiperSlide } from 'swiper/react'

import { getSliders } from '../services/api'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

export default function Hero() {
  const [data, setData] = useState([])

  useLayoutEffect(() => {
    async function getData() {
      await getSliders().then((response) => {
       // console.log('hero response', response.response)
        setData(response.response)
      })
    }
    getData()
  }, [])

  // useEffect(() => {
  //   console.log('data', data)
  // }, [data])

  return (
    <>
      <section className={'section-pattern hero-top'}></section>
      <section id={'hero'}>
        <Swiper
          // install Swiper modules
          modules={[Pagination, A11y]}
          spaceBetween={0}
          slidesPerView={1}
          pagination={{ clickable: true }}
          // onSwiper={(swiper) => console.log(swiper)}
          // onSlideChange={() => console.log('slide change')}
          > 
          {data?.map((item) => (
            <SwiperSlide key={item.id}> 
              <a href={item.link} target='_blank' rel="noreferrer" className="hero-slider-img">
                <picture className="img">
                  <source media="(min-width:768px)" srcSet={item.image} />
                  <img src={item.image} alt="..." width={400} height={800} priority="true" />
                </picture>
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    </>
  )
}
