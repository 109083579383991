import React from 'react';

export default function Input({ 
  type = '', 
  className = '', 
  focused = false, 
  id = '', 
  name = '', 
  placeholder = '', 
  value = '', 
  checked = false, 
  onChange = () => {}, 
  isReadOnly = false, 
  autoComplete = '',
  maxLength,
}) {
  return (
    <>
      <input 
        type={type} 
        className={`${className} ${focused ? 'selected' : ''}`} 
        name={name} 
        id={id} 
        placeholder={placeholder} 
        value={value} 
        checked={checked}  
        maxLength={maxLength} 
        onChange={onChange} 
        readOnly={isReadOnly} 
        autoComplete={autoComplete}
      />
      {type === 'checkbox' && <span className="checkmark"></span>}
      {type === 'radio' && <span className="checkmark"></span>}
    </>
  );
}
