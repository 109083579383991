import { useState, useEffect, useLayoutEffect } from 'react'
import Breadcrumb from '../components/Breadcrumb'
import { useParams } from 'react-router-dom';
import EventCard from '../components/EventCard'
import axios from 'axios'
import Select from '../components/Select'
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import Input from '../components/Input'

import { getCities, getEvents, getCounties } from '../services/api'
// http://192.168.1.109:3000/events?page=1&pageSize=15 

function Event() {
  const [isActive, setIsActive] = useState(false)
  const [data, setData] = useState([])
  const { category } = useParams();
  // Filter
  const [eventType, setEventType] = useState(null);
  const [city, setCity] = useState(null);
  const [county, setCounty] = useState(null);
  const [ageInterval, setAgeInterval] = useState(null);
  const [sorting, setSorting] = useState(null);

  const [cityOptions, setCityOptions] = useState({})
  const [countyOptions, setCountyOptions] = useState({})
  const [search, setSearch] = useState('');

  useEffect(() => {
    async function getData() {
      await getEvents(1, 100, city, county, ageInterval, sorting, category).then((response) => {
        if (response && response.response?.paginatedEvents) {
          setData(response.response.paginatedEvents)
        }

      })
    }
    getData()

    async function getDataCities() {
      await getCities().then((response) => {
        if (response) {
          setCityOptions([
            ...response
          ]);
        }

      })
    }
    getDataCities()
  }, [])

  // useEffect(() => {

  // }, [city, county, ageInterval, sorting, eventType])

  const filterSubmit = () => {
    async function getData() {
      await getEvents(1, 100, city, county, ageInterval, sorting, eventType, search).then((response) => {
   
        if (response && response.response?.paginatedEvents) {
          console.log('filter response', response.response.paginatedEvents)
          setData(response.response.paginatedEvents)
        }
  
      })
    }
    getData()
  }


  const searchSubmit = () => { 
    async function getData() {
      await getEvents(1, 100, city, county, ageInterval, sorting, eventType, search).then((response) => {
        if (response && response.response?.paginatedEvents) {
          console.log('filter response', response.response.paginatedEvents)
          setData(response.response.paginatedEvents)
        }
      })
    }
    getData()
  }

  // useLayoutEffect(() => {
  //   async function getData() {
  //     await getCities().then((response) => { 
  //       setCityOptions([
  //         { value: '0', label: 'Şehir Seçiniz' },
  //         ...response
  //       ]);
  //     })
  //   }
  //   getData()
  // }, [])

  const options = [
    { value: '0', label: 'Option 1' },
    { value: '1', label: 'Option' },
    { value: '2', label: 'Option' },
  ]

  const optionsAgeGroup = [
    { value: '0-6 ay', label: '0-6 ay' },
    { value: '6-12 ay', label: '6-12 ay' },
    { value: '12-18 ay', label: '12-18 ay' },
    { value: '18-24 ay', label: '18-24 ay' },
    { value: '24-30 ay', label: '24-30 ay' },
    { value: '2-3 yas', label: '2-3 yaş' },
    { value: '3-4 yas', label: '3-4 yaş' },
    { value: '4-5 yas', label: '4-5 yaş' },
    { value: '5-6 yas', label: '5-6 yaş' },
    { value: '6-8 yas', label: '6-8 yaş' },
    { value: '8-10 yas', label: '8-10 yaş' },
    { value: '10-12 yas', label: '10-12 yaş' },
    { value: '13+ yas', label: '13+ yaş' },
  ]

  // const ageIntervals = ["0-6 ay", "6-12 ay", "12-18 ay", "18-24 ay", "24-30 ay","30-36 ay","3-6 yaş","7-12 yaş","12+"];


  const eventCat = [
    { value: 'Atölye', label: 'Atölye' },
    { value: 'Kurs', label: 'Kurs' },
    { value: 'Tiyatro', label: 'Tiyatro' },
    { value: 'Kamp', label: 'Kamp' },
  ]

  const sortingOptions = [
    { value: 'ascDate', label: 'En yeni (tarihe göre)' },
    { value: 'descDate', label: 'En eski (tarihe göre)' },
    { value: 'ascPrice', label: 'En düşük fiyat' },
    { value: 'descPrice', label: 'En yüksek fiyat' }
  ]



  const eventTypeChange = (e: any) => {
    setEventType(e.value);
  }

  const cityChange = (e: any) => {
    setCity(e.label);


    async function getData() {
      await getCounties(e.label).then((response) => {
        console.log('getCounties response', response)

        setCountyOptions([
          { value: '0', label: 'İlçe Seçiniz' },
          ...response
        ]);
      })
    }
    getData()

  }

  const countyChange = (e: any) => {
    setCounty(e.label);
  }

  const ageIntervalChange = (e: any) => {  
    const values = e.map(item => item.value).join(', ');
    console.log(values)
    setAgeInterval(values);
  }

  const sortingChange = (e: any) => {
    setSorting(e.value);
  }

  const searchChange = (e: any) => {
    setSearch(e.target.value)
  }

  return (
    <main id="event-list-main">
      <section className={'section-pattern white reverse'}></section>
      <section className="event-list-section">
        <div className="container">
          <div className="event-list-section-main">
            <div className={`${!isActive ? 'collapsed' : ''} filter-sidebar`}>
              <span className="primary-title">Etkinlikler</span>
              <button
                className="btn-filter-toggle"
                onClick={() => {
                  setIsActive(!isActive)
                }}>
                <span className="icon">
                  <span></span>
                  <span></span>
                </span>
              </button>
              <div className="filter-body">
                <div>
                  <span>Etkinlik Türü</span>
                  <Select options={eventCat} className="select" onChange={eventTypeChange} />
                </div>
                {/* <div>
                <span>Tarih</span>
                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
              </div> */}
                <div>
                  <span>İl</span>
                  <Select options={cityOptions} className="select" onChange={cityChange} />
                </div>
                <div>
                  <span>İlçe</span>
                  <Select options={countyOptions} disabled className="select" onChange={countyChange} />
                </div>
                <div>
                  <span>Yaş Grubu</span>
                  <Select options={optionsAgeGroup} isMulti={true} className="select" onChange={ageIntervalChange} />
                </div>
                <div>
                  <span>Sıralama</span>
                  <Select options={sortingOptions} className="select" onChange={sortingChange} />
                </div>
              </div>
              <button type='button' onClick={filterSubmit} className="btn-filter">Şimdi Listele!</button>
            </div>
            <div>
            <div className="form-group mb-0 margin-top-small-in-mobile event-search-form">
                  <label className="label" htmlFor="userName">
                    Etkinlik Ara:
                  </label>
                  <Input type={'text'} className={'textbox'} id={'title'} name={'userName'} maxLength="40" autoComplete='off' value={search ? search : ''} onChange={searchChange} />
                  <button onClick={searchSubmit} className='btn btn-orange btn-rounded'>Ara</button>
                </div>
              <div className="list-container">{data && data.map((item) => <EventCard key={item.id} data={item} />)}</div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default Event
