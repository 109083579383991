'use client'
import { useEffect, useRef, useState } from 'react'
import Breadcrumb from '../components/Breadcrumb'
import BlogCard from '../components/BlogCard'
import Input from '../components/Input'
import Validation from '../components/Validation'
import PartnershipSuccessModal from '../components/PartnershipSuccessModal'
import { PatternFormat } from 'react-number-format'

import { businessPartner } from "../services/api"

function PartnerShip() {
  const [userName, setUserName] = useState('')
  const [phone, setPhone] = useState('')
  // const [dateOfBirth, setDateOfBirth] = useState('')
  const [email, setEmail] = useState('') 
  const [modal, setModal] = useState(false) 
  const [kvkk, setKvkk] = useState(false)
  const [company, setCompany] = useState('')
  const [contracts, setContracts] = useState(false)
  const [validationText, setValidationText] = useState('')
  const [validationState, setValidationState] = useState(false)
  const [validationTitle, setValidationTitle] = useState('Hata')
  const [loading, setLoading] = useState(false);

  const userNameChange = (e: any) => {
    setUserName(e.target.value)
  }

  const phoneChange = (e: any) => {
    setPhone(e.target.value)
  }

  const companyChange = (e: any) => {
    setCompany(e.target.value)
  } 

  const emailChange = (e: any) => {
    setEmail(e.target.value)
  } 

  const kvkkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKvkk(e.target.checked)
  }

  const contractsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setContracts(e.target.checked)
  }

  const handleChange = (e: any) => {
    e.preventDefault() 

    if (userName === '') {
      setValidationText('Lütfen ad soyad giriniz.')
      setValidationState(true)
      return false
    }

    if (company === '') {
      setValidationText('Lütfen şirket adınızı giriniz.')
      setValidationState(true)
      return false
    }

    if (email === '') {
      setValidationText('Lütfen e-posta adresinizi giriniz.')
      setValidationState(true)
      return false
    }

    if (phone === '') {
      setValidationText('Lütfen telefon numaranızı giriniz.')
      setValidationState(true)
      return false
    }

    if (!kvkk) {
      setValidationText('Lütfen gerekli belgeleri onaylayın!')
      setValidationState(true)
      return false
    }


    setLoading(true);

    async function getData() {
      await businessPartner(userName, company, email, phone).then((response) => {
        setLoading(false);

        setUserName("");
        setPhone("");
        setCompany("");
        setEmail("");
        setKvkk("");
        setKvkk(false);
        setContracts(false);

        if (response.err === null) {
          setValidationTitle('Başarılı');
          setValidationText('Formunuz başarıyla gönderildi.')
          setValidationState(true)

        } else {
          setValidationTitle('Hata');
          setValidationText('İşleminiz başarısız oldu lütfen tekrar deneyin.')
          setValidationState(true) 
        } 
 
      })
    }
    getData()

  }

  return (
    <>
      <main id="partnership-main">
        <section className={'section-pattern white reverse'}></section>
        <section className="partnership-section">
          <div className="container">
            <form onSubmit={handleChange} className="main-inner">
              <h1 className="heading-page-title">İş Ortağı Ol</h1>
              <div className="two-col-form-container form-container partnership mt">
                <div className="form-group">
                  <label className="label" htmlFor="userName">
                    Ad Soyad:
                  </label>
                  <Input type={'text'} className={'textbox'} id={'title'} name={'userName'} value={userName ? userName : ''} onChange={userNameChange} />
                </div>
                <div className="form-group">
                  <label className="label" htmlFor="phone">
                    Şirket Tam Adı:
                  </label>
                  <Input type={'text'} className={'textbox'} id={'phone'} name={'phone'} value={company ? company : ''} onChange={companyChange} />
                </div>
                <div className="form-group">
                  <label className="label" htmlFor="email">
                    E-posta:
                  </label>
                  <Input type={'text'} className={'textbox'} id={'email'} name={'email'} value={email ? email : ''} onChange={emailChange} />
                </div>

                <div className="form-group">
                  <label className="label" htmlFor="phone">
                    Telefon:
                  </label> 
                  <PatternFormat format="+90 ### ### ## ##" allowEmptyFormatting mask="_" className={'textbox'} id={'phone'} name={'phone'} value={phone ? phone : ''} onChange={phoneChange} />
                </div>

                {/* <div className="form-group">
                  <label className="label" htmlFor="dateOfBirth">
                    Web Site:
                  </label>
                  <Input type={'text'} className={'textbox'} id={'dateOfBirth'} name={'dateOfBirth'} value={dateOfBirth ? dateOfBirth : ''} onChange={dateOfBirthChange} />
                </div> */}

                {/* <label htmlFor={`kvkk`} className="form-group checkbox full">
                  <Input type={'checkbox'} id={`kvkk`} name="kvkk" checked={!!kvkk} onChange={kvkkChange} />
                  <span>
                    Kampanyalardan ve bildirimlerden haberdar olmak için, <a href={'/'}>Gizlilik ve KVKK Metni</a> kapsamında elektronik ileti almak istiyorum.
                  </span>
                </label>
                <label htmlFor={`contracts`} className="form-group checkbox full">
                  <Input type={'checkbox'} id={`contracts`} name="contracts" checked={!!contracts} onChange={contractsChange} />
                  <span>
                    <a href={'/page/on-bilgilendirme-metni'}>Ön Bilgilendirme Metni</a>&#39;ni okuduğumu, <a href={'/page/kullanici-sozlesmesi'}>Kullanıcı Sözleşmesi</a>&#39;ni, <a href={'/page/mesafeli-satis-sozlesmesi'}>Mesafeli Satış Sözleşmesi</a> ve &nbsp;
                    <a href={'/page/iptal-iade-degisim-kosullari'}>İptal, İade ve Değişim Koşulları</a>&#39;nı kabul ettiğimi onaylıyorum.
                  </span>
                </label> */}

<label htmlFor={`contract`} className="form-group checkbox full">
                  <Input type={'checkbox'} id={`contract`} name="contract" checked={!!kvkk} onChange={kvkkChange} />
                  <span>
                  <a href={'/page/araci-komisyon-sozlesmesi'}>Aracı/Komisyon Sözleşmesini</a> Okudum, Kabul Ediyorum.
                  </span>
                </label>
                <div className="form-group btn-group">
                  <button
                    className={`btn btn-rounded btn-orange btn-loading ${loading && 'btn-loading-pending'}`}
                    type='submit'>
                    <i className="spin"></i>
                    İş Ortağı Ol
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="partnership-featured-image">
            <img src="/assets/images/right-child-image.png" alt="..." width={274} height={728} />
          </div>
        </section>
      </main>

      {modal && <PartnershipSuccessModal setEvent={setModal} />}
      {validationState && <Validation setEvent={setValidationState} text={validationText} title={validationTitle} />}
    </>
  )
}

export default PartnerShip
