import React, { useEffect, useState, useLayoutEffect } from "react";
import { useParams } from 'react-router-dom';

import { verifyUser } from "../services/api";
import { useCookies } from 'react-cookie';

function VerifyAccount() {
    const { code } = useParams();
    const [cookies, setCookie] = useCookies(['access_token', 'userData']);


    useLayoutEffect(() => { 
        console.log("code", code)
        console.log("cookies", cookies)
        if(code && cookies.userData.email){
            verifyUser(cookies.userData.email, code)
            .then((response) => { 
                let expires = new Date();
                expires.setTime(expires.getTime() + (60*60*60*1000))
                let newcookie = { ...cookies };
                newcookie.userData.verified = true; 
                console.log("newcookie", newcookie);
               setCookie('userData', newcookie.userData, { path: '/', expires });
              window.location.href = "/";
            })
            .catch((error) => {
                console.error('Bir hata oluştu:', error);
            });
        } 


    }, [])
    return (
        <>

        </>
    )
}

export default VerifyAccount
