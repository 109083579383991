'use client' 

export default function BlogCard({image, link, name, id}) {
  return (
    <>
      <div className="blog-category-card">
        <a href={'/blogs/category/'+ link} className="img">
          <img src={image} alt="..." width={350} height={250} />
          <h3 className="primary-title">{name}</h3>
        </a> 
      </div>
    </> 
  )
}
