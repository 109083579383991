'use client'
import { useEffect, useRef, useState } from 'react'
import { useCookies } from 'react-cookie'

import Input from './Input'
import { signIn, forgetPassword } from '../services/api';

export default function AuthModal({ setEvent }) {
  const [userName, setUserName] = useState(null)
  const [userPassword, setUserPassword] = useState(null)
  const [userEmail, setUserEmail] = useState('')
  const [isPasswordText, setIsPasswordText] = useState(false)
  const [tab, setTab] = useState(0)
  const [successBox, setSuccessBox] = useState(false)
  const [cookies, setCookie] = useCookies(['access_token', 'userData'])
  const [loading, setLoading] = useState(false)
  const [forgotPasswordLoading, setForgotPasswordLoading] = useState(false)
  const [validation, setValidation] = useState('')
  const [validationForgotPassword, setValidationForgotPassword] = useState('')


  const userNameChange = (e: any) => {
    setUserName(e.target.value)
  }

  const passwordChange = (e: any) => {
    setUserPassword(e.target.value)
  }

  const userEmailChange = (e: any) => {
    setUserEmail(e.target.value)
  }

  const setTabEvent = (e: any) => {
    setTab(e)
  }

  const handleChange = (e: any) => {
    e.preventDefault()

    if (userName === '') {
      setValidation('Lütfen kullanıcı adı giriniz.');
      return false
    }

    if (userPassword === '') {
      setValidation('Lütfen şifre giriniz.');
      return false
    }


    async function getData() {
      setLoading(true);
      await signIn(userName, userPassword).then((response) => {
        if (response.response !== null) {
          let expires = new Date()
          expires.setTime(expires.getTime() + (60 * 60 * 60 * 1000))
          setCookie('access_token', response.response.token, { path: '/', expires })
          setCookie('userData', response.response, { path: '/', expires })
          setLoading(false);
          setValidation('Başarıyla giriş yapıldı.');
          setEvent(false)

        } else {
          setValidation('Login hatalı');
          setLoading(false);
        }

      })
    }
    getData()

  }

  const forgetPasswordHandleChange = (e: any) => {
    e.preventDefault()

    if (userEmail === '') {
      setValidationForgotPassword("E-posta adresinizi girin.");
      return false
    }

    async function getData() {
      setForgotPasswordLoading(true);
      await forgetPassword(userEmail).then((response) => {
        if (response.response !== null) { 
          setSuccessBox(true);
          setForgotPasswordLoading(false);  
        } else { 
          setValidationForgotPassword("Hatalı e-posta adresi.");
          setForgotPasswordLoading(false);
        }

      })
    }
    getData()


  }

  return (
    <>
      <div className={`modal modal-auth tab-${tab}`}>
        <div
          className="modal-shadow"
          onClick={() => {
            setEvent(false)
          }}></div>
        <div className="modal-inner">
          <div className="modal-header">
            {tab == 1 && (
              <button
                className="btn-modal-back"
                onClick={(e: any) => {
                  setTabEvent(0)
                }}>
                <img src="/assets/images/shapes/blue-shape-sm.svg" width={50} height={50} alt="Circle Shape" priority />
                <img src="/assets/images/icon/back.svg" width={25} height={25} alt="Back" priority />
              </button>
            )}
            <span className="primary-title">
              {tab == 0 && <span>Üye Girişi</span>}
              {tab == 1 && <span>Şifremi Unuttum</span>}
            </span>
            <button
              className="btn-modal-close"
              onClick={() => {
                setEvent(false)
              }}>
              <img src="/assets/images/shapes/blue-shape-sm.svg" width={50} height={50} alt="Circle Shape" priority />
              <img src="/assets/images/icon/close-white.svg" width={15} height={18} alt="Close" priority />
            </button>
            <div className={'section-pattern reverse'}></div>
          </div>
          <div className="modal-body">
            <form onSubmit={handleChange} className="auth-modal-form">
              <span className={`valitation-text ${validation !== '' ? 'active' : ''}`}>{validation}</span>
              <div className="form-group">
                <label className="label" htmlFor="userName">
                  E-posta Adresi:
                </label>
                <Input type={'text'} className={'textbox'} id={'title'} name={'userName'} value={userName ? userName : ''} onChange={userNameChange} />
              </div>
              <div className="form-group mb-half">
                <label className="label" htmlFor="userPassword">
                  Şifre:
                </label>
                <div className="icon-huge">
                  <Input
                    type={isPasswordText ? 'text' : 'password'}
                    className={'textbox'}
                    id={'userPassword'}
                    name={'userPassword'}
                    value={userPassword ? userPassword : ''}
                    onChange={passwordChange}
                  />
                  <button
                    type="button"
                    className={`icon password ${!isPasswordText && 'open'}`}
                    id={'passwordToggle'}
                    onClick={(e: any) => {
                      setIsPasswordText(!isPasswordText)
                    }}>
                    <img src="/assets/images/icon/show-hide.svg" width={24} height={24} alt=" " />
                  </button>
                </div>
              </div>
              <button
                className="btn-info-link ml-space"
                type='button'
                onClick={(e: any) => {
                  setTabEvent(1)
                }}>
                Şifremi Unuttum
              </button>
              <div className="primary-btn-huge">
                <button className={`btn btn-rounded btn-orange btn-loading ${loading && 'btn-loading-pending'}`}>
                  <i className="spin"></i>Giriş Yap
                </button>
              </div>
            </form>
            <form onSubmit={forgetPasswordHandleChange} className="auth-modal-form">
              <span className={`valitation-text ${validationForgotPassword !== '' ? 'active' : ''}`}>{validationForgotPassword}</span>
              <div className="form-group mb-half">
                <label className="label" htmlFor="userEmail">
                  E-posta Adresi:
                </label>
                <Input type={'text'} className={'textbox'} id={'userEmail'} name={'userEmail'} value={userEmail ? userEmail : ''} onChange={userEmailChange} />
              </div>
              <span className="btn-info-link ml-space">Lütfen kayıtlı e-posta adresinizi giriniz.</span>
              <div className="primary-btn-huge"> 
                <button className={`btn btn-rounded btn-orange btn-loading ${forgotPasswordLoading && 'btn-loading-pending'}`}>
                  <i className="spin"></i>Gönder
                </button>
              </div>
              <div className={`auth-modal-form-result-box ${successBox ? 'active' : ''}`}>
                <img src="/assets/images/icon/check-circle.svg" width={40} height={40} alt="Check" />
                <span>Yeni şifreniz, kayıtlı e-posta adresinize iletilmiştir. Lütfen kontrol ediniz.</span>
                <button
                  className="btn btn-rounded btn-orange"
                  type='button'
                  onClick={(e: any) => {
                    setTabEvent(0)
                  }}>
                  Tamam
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
