'use client'
import { useState, useEffect } from 'react' 
import AuthModal from '../components/AuthModal' 
import { useCookies } from 'react-cookie'
export default function Header(props) {
  const [isActiveMenu, setIsActiveMenu] = useState(false)
  const [authModal, setAuthModal] = useState(false)
  const [cookies, setCookie] = useCookies(['access_token','userData'])
  const [isLogin, setIsLogin] = useState(false)

  useEffect(() => {
    if (isActiveMenu) {
      document.body.classList.add('mobile-navigation-active')
    } else {
      document.body.classList.remove('mobile-navigation-active')
    }
  }, [isActiveMenu])

  useEffect(() => {
    if(cookies.access_token){
      console.log(cookies)
      setIsLogin(true);
    }
  }, [cookies])
 
  const logOut = () =>{
    console.log("logOut");
    function deleteCookie(name) {
      document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    }
    
    // 'userData' adlı çerezi silmek için fonksiyonu çağırın
    deleteCookie('userData');
    deleteCookie('access_token');

    // location.reload();
    document.location.href="/";

  }

  return (
    <>
      <header id="header" className="header" style={ props.style }>
        <div className="container">
          <div className="main-inner">
            <a href={'/'} className="logo">
              <img src="/assets/images/logo/logo.svg" alt="Totili Potili" width={420} height={174.58} priority="true" />
            </a>
            <ul className="primary-menu">
              <li>
                <a href={'/'}>Anasayfa</a>
              </li>
              <li>
                <a href={'/about'}>Hakkımızda</a>
              </li>
              <li>
                <a href={'/events'}>Etkinlikler</a>
              </li>
              <li>
                <a href={'/blog'}>Blog</a>
              </li>
              <li>
                <a href={'/partnership'}>İş Ortağı Ol!</a>
              </li>
            </ul>
            <ul className="auth-btn-list">
              {!isLogin &&
                <>
                  <li>
                    <a className="btn-has-svg" href={'/sign-up'}>
                      <span>Üye Ol</span>
                      <img src="/assets/images/icon/circle-orange.svg" alt="Orange Circle" className="" width={135} height={135} priority="true" />
                    </a>
                  </li>
                  <li>
                    <button
                      className="btn-has-svg"
                      onClick={(e: any) => {
                        setAuthModal(true)
                      }}>
                      <span>Üye Girişi</span>
                      <img src="/assets/images/icon/circle-blue.svg" alt="Blue Circle" className="" width={135} height={135} priority="true" />
                    </button>
                  </li>
                </>
              } 
              {isLogin &&
                <li className='user-dropdown-huge'>
                  <button
                    className="btn-has-svg"
                    onClick={(e: any) => {
                      // setAuthModal(true)
                    }}>
                    <span>
                    <img src="/assets/images/icon/user.svg" alt="User" className="" width={60} height={60} priority="true" />
                    </span>
                    <img src="/assets/images/icon/circle-blue.svg" alt="Blue Circle" className="" width={135} height={135} priority="true" />
                  </button>
                  <ul className="user-dropdown-menu">
                    <li><a href={'/profile'}>Üyelik Bilgilerim</a></li>
                    <li><a href={'/profile-events'}>Biletlerim</a></li>
                    {/* <li><a href={'/'}>Şifre Değiştir</a></li> */}
                    <li><button  onClick={(e: any) => {
                      // setAuthModal(true)
                      logOut();
                    }}>Çıkış Yap</button></li>
                  </ul>
                </li>
              }
              <li className="visible-mobile">
                <button
                  className="btn-has-svg"
                  onClick={() => {
                    setIsActiveMenu(!isActiveMenu)
                  }}>
                  <img src="/assets/images/icon/circle-red.svg" alt="Blue Circle" className="" width={135} height={135} priority="true"/>
                  <img src="/assets/images/icon/hamburger.svg" alt="Hamburger" className="" width={30} height={15} priority="true"/>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </header>
      <nav id="mobile-navigation">
        <div className="nav-top">
          <img src="/assets/images/mobile-navigation/bg.svg" width={600} height={300} alt="Background" className="bg" priority="true"/>
          <button
            className="close-btn"
            onClick={() => {
              setIsActiveMenu(!isActiveMenu)
            }}>
            <img src="/assets/images/mobile-navigation/circle-shape.svg" width={50} height={50} alt="Circle Shape" priority="true"/>
            <img src="/assets/images/mobile-navigation/close.svg" width={20} height={20} alt="Close" priority="true"/>
          </button>
        </div>
        <div className="nav-body">
          <a href={'/'}>
            <img src="/assets/images/mobile-navigation/home.svg" width={77} height={72} alt="Home" priority="true"/>
            <span>Anasayfa</span>
          </a>
          <a href={'/about'}>
            <img src="/assets/images/mobile-navigation/home.svg" width={77} height={72} alt="Hakkımızda" priority="true"/>
            <span>Hakkımızda</span>
          </a>
          <a href={'/events'}>
            <img src="/assets/images/mobile-navigation/ticket.svg" width={90} height={79} alt="Ticket" priority="true"/>
            <span>Etkinlikler</span>
          </a>
          <a href={'/blog'}>
            <img src="/assets/images/mobile-navigation/comment.svg" width={87} height={77} alt="Comment" priority="true"/>
            <span>Blog</span>
          </a>
          <a href={'/partnership'}>
            <img src="/assets/images/mobile-navigation/puzzle.svg" width={80} height={80} alt="Puzzle" priority="true"/>
            <span>İş Ortağı Ol</span>
          </a>
        </div>
      </nav>

      {authModal && <AuthModal setEvent={setAuthModal} />}
    </>
  )
}
