'use client'
import { useEffect,useLayoutEffect, useRef, useState } from 'react' 
import moment from 'moment'; 
import 'moment/locale/tr'  // without this line it didn't work
import Breadcrumb from '../components/Breadcrumb'

import BlogCard from '../components/BlogCard'

import Input from '../components/Input'
import ChangePasswordModal from '../components/ChangePasswordModal'
import { useCookies } from 'react-cookie'

import {getUserDetail} from '../services/api';
moment.locale('tr');
function Profile() {
  const [userName, setUserName] = useState(null)
  const [phone, setPhone] = useState(null)
  const [dateOfBirth, setDateOfBirth] = useState(null)
  const [email, setEmail] = useState(null)
  const [userID, setUserID] = useState(null)
  const [userPassword, setUserPassword] = useState(null) 
  const [modal, setModal] = useState(false)
  const [cookies] = useCookies(['userData','access_token']);


  useLayoutEffect(()=>{
    if(cookies && cookies.userData){
      console.log("cookies",cookies)
      console.log("cookies.uuid", cookies.uuid)
      setUserID(cookies.userData.id)
    } 
  },[cookies])


  useEffect(()=>{
    if(userID !== null){
      async function getData() {
        await getUserDetail(userID,cookies.access_token).then((response) => { 
          console.log("response",response);
          setUserName(response.response[0].name +' '+ response.response[0].surname);
          setPhone(response.response[0].phone_number);
          setDateOfBirth(moment(response.response[0].child_birth_date).format('D MMMM YYYY'));
          setEmail(response.response[0].email);
        })
      }
      getData()

    } 
    
  },[userID])

  const userNameChange = (e: any) => {
    setUserName(e.target.value)
  }

  const phoneChange = (e: any) => {
    setPhone(e.target.value)
  }

  const dateOfBirthChange = (e: any) => {
    setDateOfBirth(e.target.value)
  }

  const emailChange = (e: any) => {
    setEmail(e.target.value)
  }

  const passwordChange = (e: any) => {
    setUserPassword(e.target.value)
  }

  return (
    <>
      <main id="profile-main">
        <section className={'section-pattern white reverse'}></section>
        <section className="profile-section">
          <div className="container">
            <div className="main-inner">
              <h1 className="heading-page-title">Üyelik Bilgilerim</h1>
              <div className="two-col-form-container form-container mt">
                <div className="form-group">
                  <label className="label" htmlFor="userName">
                    Ad Soyad:
                  </label>
                  <Input type={'text'} className={'textbox'} id={'title'} name={'userName'} value={userName ? userName : ''} onChange={userNameChange} isReadOnly={true} />
                </div>
                <div className="form-group">
                  <label className="label" htmlFor="phone">
                    Telefon:
                  </label>
                  <Input type={'text'} className={'textbox'} id={'phone'} name={'phone'} value={phone ? phone : ''} onChange={phoneChange} isReadOnly={true}/>
                </div>
                <div className="form-group">
                  <label className="label" htmlFor="dateOfBirth">
                    Çocuğunuzun Doğum Tarihi:
                  </label>
                  <Input type={'text'} className={'textbox'} id={'dateOfBirth'} name={'dateOfBirth'} value={dateOfBirth ? dateOfBirth : ''} onChange={dateOfBirthChange}  isReadOnly={true}/>
                </div>
                <div className="form-group">
                  <label className="label" htmlFor="email">
                    E-mail:
                  </label>
                  <Input type={'text'} className={'textbox'} id={'email'} name={'email'} value={email ? email : ''} onChange={emailChange} isReadOnly={true} />
                </div> 
                <div className="form-group btn-group">
                  <button
                    className="btn btn-rounded btn-orange"
                    onClick={(e: any) => {
                      setModal(!modal)
                    }}>
                    Şifre Değiştir
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {modal && <ChangePasswordModal setEvent={setModal} />}
    </>
  )
}

export default Profile
