import { createContext, useState, useEffect, useLayoutEffect } from 'react';
import { toast, Toaster, ToastBar } from 'react-hot-toast';
// Image Sources
// import CloseIcon from "../assets/img/icon/toast-close.svg";
export const Context = createContext()
const Provider = ({ children }) => {
    const [showModal, setShowModal] = useState(false); // Modal aç/kapa işlemleri için kullanılan context state'i
    const [showFilterModal, setShowFilterModal] = useState(false); // Filtreleme modalı açma/kapama için kullanılıyor
    const [playlistId, setPlaylistId] = useState(null); // PlaylistID
    const [userDataContext, setUserDataContext] = useState(null); // User Bilgisi
    const storedData = localStorage.getItem('userData'); // LocalStorage kullanıcı bilgisi
    const [toastSuccess, setToastSuccess] = useState(
        {
            status: false,
            title: '',
            message: ''
        }
    );
    const [toastError, setToastError] = useState(
        {
            status: false,
            title: '',
            message: ''
        }
    );

    useLayoutEffect(() => {
        // Localstorage içerisinde kullanıcı bilgisi varsa context'e set ediliyor.
        if (storedData) {
            setUserDataContext(JSON.parse(storedData));
        }
    }, [setUserDataContext, storedData]);

    useEffect(() => {
        if (toastSuccess.status === true) {
            toast.success(
                <>
                    <span>{toastSuccess.title}</span>
                    <p>{toastSuccess.message}</p>
                </>
                , {
                    className: 'toast-custom success',
                    position: 'top-right'
                });

            setToastSuccess({ status: false, title: '', message: '' })
        }
    }, [toastSuccess]);

    useEffect(() => {
        if (toastError.status === true) {
            toast.error(
                <>
                    <span>{toastError.title}</span>
                    <p>{toastError.message}</p>
                </>
                , {
                    className: 'toast-custom error',
                    position: 'top-right'
                });

            setToastError({ status: false, title: '', message: '' })
        }
    }, [toastError]);

    const data = {
        toastSuccess,
        setToastSuccess,
        toastError,
        setToastError,
        showModal,
        setShowModal,
        showFilterModal,
        setShowFilterModal,
        playlistId,
        setPlaylistId,
        userDataContext,
        setUserDataContext
    }

    return (
        <>
            <Context.Provider value={data}>
                {children}
            </Context.Provider>
            {/* <Toaster>
                {(t) => (
                    <ToastBar toast={t}>
                        {({ icon, message }) => (
                            <>
                                {icon}
                                {message}
                                {t.type !== 'loading' && (
                                    <button onClick={() => toast.dismiss(t.id)}>
                                        <img src={CloseIcon} alt=" " />
                                    </button>
                                )}
                            </>
                        )}
                    </ToastBar>
                )}
            </Toaster> */}
        </>
    )
}
export default Provider;