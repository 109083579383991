import React from 'react'


export default function Breadcrumb({title}) {
  return (<>
    <div className="breadcrumb">
      <div className="container">
        <h1 className="primary-title">{title}</h1>
      </div>
    </div> 
    <section className={'section-pattern reverse'}></section>
  </>)
}