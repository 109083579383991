'use client'
import { useEffect, useRef, useState } from 'react' 

import Input from './Input'

export default function Validation({ setEvent, text = null, title = 'Hata' }) {
  return (
    <>
      <div className={`modal modal-auth`}>
        <div
          className="modal-shadow"
          onClick={() => {
            setEvent && setEvent(false)
          }}></div>
        <div className="modal-inner">
          <div className="modal-header">
            <span className="primary-title">
              <span>{title}</span>
            </span>
            <button
              className="btn-modal-close"
              onClick={() => {
                setEvent && setEvent(false)
              }}>
              <img src="/assets/images/shapes/blue-shape-sm.svg" width={50} height={50} alt="Circle Shape" priority="true" />
              <img src="/assets/images/icon/close-white.svg" width={15} height={18} alt="Close" priority="true" />
            </button>
            <div className={'section-pattern reverse'}></div>
          </div>
          <div className="modal-body">
            <div className="auth-modal-form">
              <div className={`auth-modal-form-result-box active type-1`}>
                <span>{text}</span>
                <button
                  className="btn btn-rounded btn-orange"
                  onClick={(e: any) => {
                    setEvent && setEvent(false)
                  }}>
                  Tamam
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
