import React, { useLayoutEffect, useState, useEffect } from 'react'
import Breadcrumb from '../components/Breadcrumb'
import { useParams } from 'react-router-dom';
import BlogCard from '../components/BlogCard'
import { blogCategories } from '../services/api';

function Blog() {
    const [data, setData] = useState([])
    const { link } = useParams();

    useLayoutEffect(() => {
        console.log("link",link);
        async function getData() {
            await blogCategories(link).then((response) => {
                // console.log('hero response', response.response)
                console.log("response blog category", response)
                setData(response.response)
            })
        }
        getData()
    }, [])

    return (
        <main id="blog-list-main">
            <Breadcrumb title="Blog" />
            <section className="blog-list-section">
                <div className="container">
                    <div className="list-container">
                        {data &&
                            data.map((item, index) => (
                                <div key={index} className="blog-card type-2">
                                    <a href={'/blog/' + item.id} className="img">
                                        <img src={item.image} alt="..." width={350} height={250} />
                                    </a>
                                    <div className="detail">
                                        <a href={'/blog/' + item.id}> 
                                            <h3 className="primary-title">{item.title}</h3>
                                        </a>
                                        <span className="bottom-huge">
                                            <a href={'/blog/' + item.id} className="btn btn-rounded btn-orange">
                                                Devamını Oku
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </section>
        </main>
    )
}

export default Blog
