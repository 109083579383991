import React from 'react'

export default function Loader() {
  return (
    <>
      <div className='loader-container'>
      <span className="loader"></span>
      </div>
     
    </>
  )
}
