import Breadcrumb from '../components/Breadcrumb';
import { useEffect, useRef, useState } from 'react';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import Validation from '../components/Validation';
import PartnershipSuccessModal from '../components/PartnershipSuccessModal';
import { PatternFormat } from 'react-number-format';
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import { contactUs } from '../services/api';
import Input from '../components/Input';
import Select from '../components/Select';
import axios from 'axios';

function Faq() {
  const [subject, setSubject] = useState('');
  const [userName, setUserName] = useState('');
  const [phone, setPhone] = useState('');
  // const [dateOfBirth, setDateOfBirth] = useState('')
  const [email, setEmail] = useState('');
  const [message, setMesssage] = useState('');
  const [modal, setModal] = useState(false);
  const [kvkk, setKvkk] = useState(false);
  const [contracts, setContracts] = useState(false);
  const [validationText, setValidationText] = useState('');
  const [validationState, setValidationState] = useState(false);
  const [validationTitle, setValidationTitle] = useState('Hata');
  const [loading, setLoading] = useState(false);

  const subjectChange = (e: any) => {
    setSubject(e.value);
  };

  const subjectOptions = [
    { value: 'Satın Aldığım Etkinlik İle İlgili Sorun Yaşıyorum.', label: 'Satın Aldığım Etkinlik İle İlgili Sorun Yaşıyorum.' },
    { value: 'Satın Almak İstediğim Etkinlik Hakkında Soru Sormak İstiyorum. ', label: 'Satın Almak İstediğim Etkinlik Hakkında Soru Sormak İstiyorum. ' },
    { value: 'Öneri', label: 'Öneri' },
    { value: 'Şikayet', label: 'Şikayet' },
    { value: 'Teşekkür', label: 'Teşekkür' },
    { value: 'Diğer', label: 'Diğer' },
  ];

  const userNameChange = (e: any) => {
    setUserName(e.target.value);
  };

  const phoneChange = (e: any) => {
    setPhone(e.target.value);
  };

  const emailChange = (e: any) => {
    setEmail(e.target.value);
  };

  const messageChange = (e: any) => {
    setMesssage(e.target.value);
  };

  const kvkkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKvkk(e.target.checked);
  };

  const contractsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setContracts(e.target.checked);
  };

  const handleChange = (e: any) => {
    e.preventDefault();

    if (subject === '') {
      setValidationText('Lütfen konu seçiniz.');
      setValidationState(true);
      return false;
    }

    if (userName === '') {
      setValidationText('Lütfen ad soyad giriniz.');
      setValidationState(true);
      return false;
    }

    if (email === '') {
      setValidationText('Lütfen e-posta adresinizi giriniz.');
      setValidationState(true);
      return false;
    }

    if (phone === '') {
      setValidationText('Lütfen telefon numaranızı giriniz.');
      setValidationState(true);
      return false;
    }

    if (message === '') {
      setValidationText('Lütfen mesajınızı giriniz.');
      setValidationState(true);
      return false;
    }

    if (!kvkk || !contracts) {
      setValidationText('Lütfen gerekli belgeleri onaylayın!');
      setValidationState(true);
      return false;
    }

    setLoading(true);

    async function getData() {
      await contactUs( subject, userName, email, phone, message).then((response) => {
        setLoading(false);

        setSubject('');
        setUserName('');
        setPhone('');
        setEmail(''); 
        setMesssage('');
        setKvkk(false);
        setContracts(false);

        if (response.err === null) {
          setValidationTitle('Başarılı');
          setValidationText('Formunuz başarıyla gönderildi.');
          setValidationState(true);
        } else {
          setValidationTitle('Hata');
          setValidationText('İşleminiz başarısız oldu lütfen tekrar deneyin.');
          setValidationState(true);
        }
      });
    }
    getData();
  };

  return (
    <>
      <main id="faq-list-main">
        <Breadcrumb title="Sıkça Sorulan Sorular" />
        <section className="faq-list-section">
          <div className="container">
            <div className="main-inner">
              <h2 className="heading-2">ONLİNE (ÇEVRİMİÇİ) ETKİNLİKLER</h2>
              <Accordion>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>Çevirimiçi bir etkinliğe nasıl katılabilirim ?</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>Etkinliği satın alma işlemini gerçekleştirdikten sonra kayıtlı e-posta adresinize etkinliğe giriş bilgileriniz iletilecektir. Etkinlik tarih ve saatinde bu bilgileri kullanarak etkinliğe giriş yapabilirsiniz.</p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>Giriş bilgilerim gelmedi. Hatalı giriş bilgisi geldi. Giriş yapamıyorum. Ne yapmalıyım?</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Haftanın her günü 10:00 -16:00 saatleri arasında Whatsapp üzerinden destek talebinde bulunabilir ya da <b>info@totilipotili.com</b> e-posta adresine talebinizi iletebilirsiniz.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>Etkinlik sırasında bağlantı ile ilgili sorun yaşarsam aynı giriş bilgileri ile tekrar etkinliğe giriş yapabilir miyim?</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>Evet. Bağlantınız koparsa veya bir sorun yaşarsanız, etkinlik süresince aynı bilgiler ile tekrar giriş yapabilirsiniz.</p>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
              <h2 className="heading-2">HESABIM</h2>
              <Accordion>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>Nasıl üye olabilirim?</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>Ana sayfada bulunan “kayıt ol” linki aracılığıyla üye olabilirsiniz. Sizden istenen bilgileri eksiksiz olarak doldurmanız gerekmektedir.</p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>Hesabımın şifresini nasıl değiştirebilirim?</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>Hesabınıza giriş yaptıktan sonra "Profilim" bölümünün alt kısmında yer alan "Şifre Değiştir" bölümünden şifrenizi değiştirebilirsiniz.</p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>Hesabımın şifresini unuttum, ne yapmalıyım?</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>Hesabınızın şifresini unuttuysanız, ‘Giriş Yap’ sayfasındaki ‘Şifrenizi mi unuttunuz?’ linkine tıklamanız yeterlidir. Şifreniz, kayıtlı e-posta adresinize gönderilecektir.</p>
                    <p>E-postanızın size ulaşıp ulaşmadığından emin olmak için "gereksiz postalar" kutunuzu da kontrol etmeniz gerekmektedir.</p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>TotiliPotili’ye üye olmadan etkinlik bileti satın alabilir miyim?</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>Üye olmadan bilet satın alma işlemi yapamazsınız.</p>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
              <h2 className="heading-2">İPTAL, İADE, DEĞİŞİKLİK KOŞULLARI</h2>
              <Accordion>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>Bilet satın aldığım etkinliğe katılamayacağım. İptal etmek istiyorum. Ne yapmalıyım?</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>Mesafeli Satış Sözleşmesi’nin 5. Maddesi bu konu hakkındadır.</p>
                    <h4>5. CAYMA HAKKI</h4>
                    <p>
                      Müşteri, işbu sözleşmenin akdedildiği tarihten itibaren yedi gün içerisinde herhangi bir gerekçe göstermeksizin sözleşmeden cayma hakkına sahiptir. Müşterinin cayma hakkını kullanabilmesi için satın almış olduğu hizmeti hiçbir şekilde kullanmamış olması ve/veya cayma hakkı
                      süresi olan 7 günlük süreyi aşmamak kaydıyla etkinlik seansının başlamasına 5 günden fazla zaman kalması gerekmektedir, aksi halde cayma hakkı geçersiz olacaktır. Müşterinin cayma hakkını süresi içerisinde ve usulüne uygun kullanması halinde www.totilipotili.com cayma hakkı
                      bildiriminin kendisine ulaştığı tarihten itibaren en geç on dört gün içerisinde almış olduğu bilete ilişkin bedeli Müşteriye iade edecektir. Müşteri, cayma hakkı kullanımını yazılı olarak yapacak veya www.totilipotili.com’un belirtilen iletişim formuna veya e-posta adresine
                      bildirerek gerçekleştirecektir.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>Bileti satın aldım ancak etkinliğe katılamayacağımı fark ettim. İade ya da değişiklik yapabilir miyim?</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>Mesafeli Satış Sözleşmesi’nin 5. Maddesi bu konu hakkındadır.</p>
                    <h4>5. CAYMA HAKKI</h4>
                    <p>
                      Müşteri, işbu sözleşmenin akdedildiği tarihten itibaren yedi gün içerisinde herhangi bir gerekçe göstermeksizin sözleşmeden cayma hakkına sahiptir. Müşterinin cayma hakkını kullanabilmesi için satın almış olduğu hizmeti hiçbir şekilde kullanmamış olması ve/veya cayma hakkı
                      süresi olan 7 günlük süreyi aşmamak kaydıyla etkinlik seansının başlamasına 5 günden fazla zaman kalması gerekmektedir, aksi halde cayma hakkı geçersiz olacaktır. Müşterinin cayma hakkını süresi içerisinde ve usulüne uygun kullanması halinde www.totilipotili.com cayma hakkı
                      bildiriminin kendisine ulaştığı tarihten itibaren en geç on dört gün içerisinde almış olduğu bilete ilişkin bedeli Müşteriye iade edecektir. Müşteri, cayma hakkı kullanımını yazılı olarak yapacak veya www.totilipotili.com’un belirtilen iletişim formuna veya e-posta adresine
                      bildirerek gerçekleştirecektir.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>Biletimde değişiklik (seans, koltuk, tarih vb.) yapmak istiyorum. Yapabilir miyim?</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>Mesafeli Satış Sözleşmesi’nin 5. Maddesi bu konu hakkındadır.</p>
                    <h4>5. CAYMA HAKKI</h4>
                    <p>
                      Müşteri, işbu sözleşmenin akdedildiği tarihten itibaren yedi gün içerisinde herhangi bir gerekçe göstermeksizin sözleşmeden cayma hakkına sahiptir. Müşterinin cayma hakkını kullanabilmesi için satın almış olduğu hizmeti hiçbir şekilde kullanmamış olması ve/veya cayma hakkı
                      süresi olan 7 günlük süreyi aşmamak kaydıyla etkinlik seansının başlamasına 5 günden fazla zaman kalması gerekmektedir, aksi halde cayma hakkı geçersiz olacaktır. Müşterinin cayma hakkını süresi içerisinde ve usulüne uygun kullanması halinde www.totilipotili.com cayma hakkı
                      bildiriminin kendisine ulaştığı tarihten itibaren en geç on dört gün içerisinde almış olduğu bilete ilişkin bedeli Müşteriye iade edecektir. Müşteri, cayma hakkı kullanımını yazılı olarak yapacak veya www.totilipotili.com’un belirtilen iletişim formuna veya e-posta adresine
                      bildirerek gerçekleştirecektir.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
              <h2 className="heading-2">ÖDEME SEÇENEKLERİ</h2>
              <Accordion>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>Havale/EFT ile ödeme yapabilir miyim?</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>Banka havalesi ile yapılabilecek ödemeleri kabul edemiyoruz. Bilet satın almak için kredi kartı ya da banka kartınızı kullanabilirsiniz.</p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>Kredi Kartı ile taksitli ödeme yapabilir miyim?</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>Eğer kartınız ya da kampanyalarımız buna imkan sağlıyor ise ödeme sayfasında taksit imkanı size mutlaka sunulacaktır.</p>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </section>
        <section className="partnership-section">
          <div className="container">
            <form onSubmit={handleChange} className="main-inner">
              <h1 className="heading-page-title">İletişim Formu</h1>
              <div className="desc">
                <p>
                  İletişim Formu’nu doldurmadan önce sorunuza daha hızlı yanıt bulabilmek için Sıkça Sorulan Sorular sayfasında bulunan konu başlıklarına ve içeriklerine göz atabilirsiniz. Eğer bu bölümde sorunuza yanıt bulamadıysanız size yardımcı olabilmemiz için lütfen İletişim Formu’nu doldurun.
                </p>
              </div>
              <div className="two-col-form-container form-container partnership mt">
                <div className="form-group custom-select">
                  <label className="label" htmlFor="subject">
                    Konu:
                  </label>
                  <Select options={subjectOptions} className="select" onChange={subjectChange} />
                </div>
                <div className="form-group">
                  <label className="label" htmlFor="userName">
                    Ad Soyad:
                  </label>
                  <Input type={'text'} className={'textbox'} id={'title'} name={'userName'} value={userName ? userName : ''} onChange={userNameChange} />
                </div>
                <div className="form-group">
                  <label className="label" htmlFor="email">
                    E-posta:
                  </label>
                  <Input type={'text'} className={'textbox'} id={'email'} name={'email'} value={email ? email : ''} onChange={emailChange} />
                </div>

                <div className="form-group">
                  <label className="label" htmlFor="phone">
                    Telefon
                  </label>
                  <PatternFormat format="+90 ### ### ## ##" allowEmptyFormatting mask="_" className={'textbox'} id={'phone'} name={'phone'} value={phone ? phone : ''} onChange={phoneChange} />
                </div>

                <div className="form-group">
                  <label className="label" htmlFor="message">
                    Mesajınız:
                  </label>
                  <textarea
                    className={'textbox'}
                    id={'message'}
                    placeholder="Bu kısımda sorunuzu detaylı olarak açıklayın. Satın aldığınız bir etkinlikle ilgili sorununuz var ise bilet numaranızı belirtmeyi unutmayın."
                    name={'message'}
                    value={message ? message : ''}
                    onChange={messageChange}
                  ></textarea>
                </div>

                <label htmlFor={`kvkk`} className="form-group checkbox full">
                  <Input type={'checkbox'} id={`kvkk`} name="kvkk" checked={!!kvkk} onChange={kvkkChange} />
                  <span>
                    Kampanyalardan ve bildirimlerden haberdar olmak için, <a href={'/'}>Gizlilik ve KVKK Metni</a> kapsamında elektronik ileti almak istiyorum.
                  </span>
                </label>
                <label htmlFor={`contracts`} className="form-group checkbox full">
                  <Input type={'checkbox'} id={`contracts`} name="contracts" checked={!!contracts} onChange={contractsChange} />
                  <span>
                    <a href={'/page/on-bilgilendirme-metni'}>Ön Bilgilendirme Metni</a>&#39;ni okuduğumu, <a href={'/page/kullanici-sozlesmesi'}>Kullanıcı Sözleşmesi</a>&#39;ni, <a href={'/page/mesafeli-satis-sozlesmesi'}>Mesafeli Satış Sözleşmesi</a> ve &nbsp;
                    <a href={'/page/iptal-iade-degisim-kosullari'}>İptal, İade ve Değişim Koşulları</a>&#39;nı kabul ettiğimi onaylıyorum.
                  </span>
                </label>
                <div className="form-group btn-group">
                  <button className={`btn btn-rounded btn-orange btn-loading ${loading && 'btn-loading-pending'}`} type="submit">
                    <i className="spin"></i>
                    Gönder
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="partnership-featured-image">
            <img src="/assets/images/right-child-image.png" alt="..." width={274} height={728} />
          </div>
        </section>
      </main>
      {validationState && <Validation setEvent={setValidationState} text={validationText} title={validationTitle} />}
    </>
  );
}

export default Faq;
