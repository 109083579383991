
function Page() {
  return (
    <>
      <main id="blog-detail-main">
        <section className={'section-pattern white reverse'}></section>
        <section className="section-page-title">
          <div className="container">
            <div className="main-inner">
              <div>
                <h1 className="primary-title">GİZLİLİK SÖZLEŞMESİ KVKK</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="section-blog-detail">
          <div className="container">
            <div className="editor-content">
              <p>
                1) www.totilipotili.com internet sitesinde veya totilipotili aplikasyonunda sunulan tüm servisler “Ferahevler Mah. Önceler Sok. No: 3 İç Kapı No: 1 Sarıyer/İSTANBUL” adresinde faaliyet
                gösteren GÜLŞAH AZAKLI GÜNAY’a ait olup, kendisi tarafından işletilmektedir.
              </p>
              <p>
                2) İnternet sitemizde veya aplikasyonumuzda çeşitli amaçlarla kişisel veriler toplanabilir. Aşağıda, toplanan kişisel verilerin nasıl ve ne şekilde toplandığı, bu verilerin nasıl ve ne
                şekilde korunduğu belirtilmiştir.
              </p>
              <p>
                3) Üyelik üzerindeki çeşitli form ve anketlerin doldurulması suretiyle üyelerin kendileriyle ilgili birtakım kişisel bilgiler (isim-soyisim, firma bilgileri, telefon, adres veya
                e-posta adresleri gibi) internet sitemizde ve aplikasyonumuzda toplanmaktadır.
              </p>
              <p>
                4) İnternet sitemizde ve aplikasyonumuzda bazı dönemlerde yer alan kampanya bilgileri, yeni atölyeler hakkında bilgiler, promosyon teklifleri müşterilerimize/üyelerimize
                gönderilebilir. Üyelerimiz bu gibi bilgileri alıp almama konusunda her türlü seçimi kendisine gelen bilgilendirme iletisindeki linkle bildirim yapabilmektedir.
              </p>
              <p>
                5) Eposta veya mesaj ile gerçekleştirilen onay sürecinde, üyelerimiz tarafından tarafımıza elektronik ortamda iletilen kişisel bilgiler, Üyelerimiz ile yaptığımız “Kullanıcı
                Sözleşmesi” ile belirlenen amaçlar ve kapsam dışında üçüncü kişilere açıklanmayacaktır.
              </p>
              <p>
                6) Sistemle ilgili sorunların tanımlanması ve verilen hizmet ile ilgili çıkabilecek sorunların veya uyuşmazlıkların hızla çözülmesi için, tarafımızca üyelerin IP adresleri
                kaydedilmektedir. IP adresleri, kullanıcıları genel bir şekilde tanımlamak ve kapsamlı demografik bilgi toplamak amacıyla da kullanılabilmektedir.
              </p>
              <p>
                7) Tarafımızca talep edilen bilgiler biz veya işbirliği içinde olduğumuz kişiler tarafından doğrudan pazarlama yapmak amacıyla kullanılabilmektedir. Kişisel bilgiler, gerektiğinde
                kullanıcıyla temas kurmak için de kullanılabilmektedir. Tarafımızca müşterilerimizden talep edilen bilgiler veya web sitemiz ve aplikasyonumuz üzerinden kullanıcı tarafından sağlanan
                bilgiler; biz ve iş birliği içinde olduğumuz kişiler tarafından, “Kullanım Sözleşmesi” ile belirlenen amaçlar ve kapsam dışında da üyelerimizin kimliği ifşa edilmeden çeşitli
                istatistiksel değerlendirmeler, veri tabanı oluşturma ve pazar araştırmalarında kullanılabilmektedir.
              </p>
              <p>
                8) Gizli bilgileri kesinlikle özel ve gizli tutmayı, bunu bir sır saklama yükümü olarak addetmeyi ve gizliliğin sağlanması ve sürdürülmesi, gizli bilginin tamamının veya herhangi bir
                kısmının kamu alanına girmesini veya yetkisiz kullanımını veya üçüncü bir kişiye ifşasını önlemek için gerekli tüm tedbirleri almayı ve gerekli özeni göstermeyi taahhüt etmekteyiz.
              </p>
              <p>
                9) İnternet sitemizden veya aplikasyonumuzdan alışveriş yapan kredi kartı sahiplerinin güvenliği ön planda tutulmaktadır. Kredi kartı bilgileriniz hiçbir şekilde sistemimizde
                saklanmamakta ve 3D Secure sistemi ile güvence altına alınmaktadır.
              </p>
              <p>
                10) İşlemler sürecine girdiğinizde güvenli bir sitede olduğunuzu anlamak için dikkat etmeniz gereken iki şey vardır. Bunlardan biri tarayıcınızın en alt satırında bulunan bir anahtar
                ya da kilit simgesidir. Bu güvenli bir internet sayfasında olduğunuzu gösterir ve her türlü bilgileriniz şifrelenerek korunmaktadır. Bu bilgiler, ancak satış işlemleri sürecine bağlı
                olarak ve verdiğiniz talimat doğrultusunda kullanılmaktadır. Alışveriş sırasında kullanılan kredi kartı ile ilgili bilgiler alışveriş sitelerimizden bağımsız olarak 128 bit SSL (Secure
                Sockets Layer) protokolü ile şifrelenip sorgulanmak üzere ilgili bankaya ulaştırılmaktadır. Kartın kullanılabilirliği onaylandığı takdirde alışverişe devam edilmektedir. Kartla ilgili
                hiçbir bilgi tarafımızdan görüntülenemediğinden ve kaydedilmediğinden, üçüncü şahısların herhangi bir koşulda bu bilgileri ele geçirmesi engellenmiş olmaktadır.
              </p>
              <p>
                11) Online olarak kredi kartı ile verilen siparişlerin ödeme/fatura/teslimat adresi bilgilerinin güvenilirliği şirketimiz tarafından kredi kartları dolandırıcılığına karşı
                denetlenmektedir. Bu yüzden, internet sitemizden ilk defa işlem yapan müşterilerin siparişlerinin tedarik ve teslimat aşamasına gelebilmesi için öncelikle finansal ve adres/telefon
                bilgilerinin doğruluğunun onaylanması gereklidir. Bu bilgilerin kontrolü için gerekirse kredi kartı sahibi müşteri ile veya ilgili banka ile irtibata geçilmektedir.
              </p>
              <p>
                12) Web sitesi veya aplikasyon dahilinde başka sitelere link verebilmemiz mümkün olup, bu linkler vasıtasıyla erişilen sitelerin gizlilik uygulamaları ve içeriklerine yönelik herhangi
                bir sorumluluk taşımamaktadır. İnternet sitemizde veya aplikasyonumuzda yayınlanan reklamlar, reklamcılık yapan iş ortaklarımız aracılığı ile kullanıcılarımıza dağıtılmaktadır. İşbu
                sözleşmedeki Gizlilik Politikası Prensipleri, sadece sitemizin ve aplikasyonumuzun kullanımına ilişkindir, üçüncü taraf web sitelerini kapsamamaktadır.
              </p>
              <p>
                13) Aşağıda belirtilen sınırlı hallerde işbu “Gizlilik Sözleşmesi” hükümleri dışında kullanıcılara ait bilgileri üçüncü kişilere açıklanabilecektir. Bu durumlar sınırlı sayıda olmak
                üzere;
              </p>
              <p>a) Kanun, Kanun Hükmünde Kararname, Yönetmelik vb. yetkili hukuki otorite tarafından çıkarılan ve yürürlükte olan hukuk kurallarının getirdiği zorunluluklara uymak;</p>
              <p>b) Kullanıcılarla akdettiğimiz “Kullanıcı Sözleşmesi”nin ve diğer sözleşmelerin gereklerini yerine getirmek ve bunları uygulamaya koymak amacıyla;</p>
              <p>c) Yetkili idari ve adli otorite tarafından usulüne göre yürütülen bir araştırma veya soruşturmanın yürütümü amacıyla kullanıcılarla ilgili bilgi talep edilmesi;</p>
              <p>d) Kullanıcıların hakları veya güvenliklerini korumak için bilgi vermenin gerekli olduğu durumlardan ibarettir.</p>
              <p>
                14) Tarafımıza herhangi bir bilet alım işlemiyle ilgili olarak göndereceğiniz epostalarda, kredi kartı numaranızı veya şifrelerinizi yazmanız durumda kredi kartı bilgilerinin çalınması
                hususunda herhangi bir sorumluluğumuz bulunmamaktadır. Epostalarda yer alan bilgiler üçüncü şahıslar tarafından görülebilmekte olup, epostalarınızdan aktarılan bilgilerin güvenliği
                hiçbir koşulda garanti edilmemektedir.
              </p>
              <p>
                15) İnternet sitemizi veya aplikasyonumuzu ziyaret eden kullanıcılar hakkındaki bilgiler teknik bir iletişim dosyası (Çerez-Cookie) kullanarak elde edilebilmektedir. Bahsi geçen teknik
                iletişim dosyaları, ana bellekte saklanmak üzere bir internet sitesinin kullanıcının tarayıcısına (browser) gönderdiği küçük metin dosyalarıdır. Teknik iletişim dosyası site hakkında
                durum ve tercihleri saklayarak internetin ve aplikasyonun kullanımını kolaylaştırmaktadır. Teknik iletişim dosyası, siteyi veya aplikasyonu kaç kişinin ziyaret ettiğini, bir kişinin
                siteyi veya aplikasyonu hangi amaçla, kaç kez ziyaret ettiğini ve ne kadar sitede/aplikasyonda kaldıkları hakkında istatistiksel bilgileri elde etmeye ve kullanıcılar için özel
                tasarlanmış kullanıcı sayfalarından dinamik olarak reklam ve içerik üretilmesine yardımcı olmaktadır. Teknik iletişim dosyası, ana bellekte veya epostanızdan veri veya başkaca herhangi
                bir kişisel bilgi almak için tasarlanmamıştır. Tarayıcıların pek çoğu başta teknik iletişim dosyasını kabul eder biçimde tasarlanmıştır ancak kullanıcılar dilerse teknik iletişim
                dosyasının gelmemesi veya teknik iletişim dosyasının gönderildiğinde uyarı verilmesini sağlayacak biçimde ayarları değiştirebilmektedir.
              </p>
              <p>
                16) İşbu “Gizlilik Sözleşmesi” hükümlerini dilediğimiz zaman internet sitesinde ve aplikasyonda yayınlamak veya kullanıcılara elektronik posta göndermek suretiyle değiştirmemiz
                mümkündür. Gizlilik Sözleşmesi hükümleri değiştiği takdirde, yayınlandığı tarihte yürürlük kazanır. Gizlilik politikamız ile ilgili her türlü soru ve önerileriniz için
                info@totilipotili.com adresine eposta gönderebilirsiniz. Aşağıdaki iletişim bilgilerinden tarafımıza ulaşabilirsiniz.
              </p>

              <p>
                Yetkili: GÜLŞAH AZAKLI GÜNAY <br />
                Adres: Ferahevler Mah. Önceler Sok. No: 3 İç Kapı No: 1 Sarıyer/İSTANBUL <br />
                Eposta: info@totilipotili.com <br /> 
              </p>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}

export default Page
