'use client'
import { useEffect, useRef, useState } from 'react'

import Input from './Input'
import { useCookies } from 'react-cookie'
import { changePassword } from "../services/api";

export default function ChangePasswordModal({ setEvent }) {
  const [userPassword, setUserPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [isPasswordText, setIsPasswordText] = useState(false)
  const [isNewsPasswordText, setIsNewsPasswordText] = useState(false)
  const [successBox, setSuccessBox] = useState(false)
  const [cookies, setCookie] = useCookies(['access_token', 'userData'])
  const [validation, setValidation] = useState('') 

  const passwordChange = (e: any) => {
    setUserPassword(e.target.value)
  }

  const newPasswordChange = (e: any) => {
    setNewPassword(e.target.value)
  }

  const handleChange = (e: any) => {
    e.preventDefault()

    if (userPassword === '') {  
      setValidation('Lütfen mevcut şifrenizi giriniz.');
      return false
    }

    if (newPassword === '') {
      setValidation('Lütfen yeni şifre giriniz.');
      return false
    }

    if(userPassword === newPassword){
      setValidation('Eski şifreniz yenisi ile aynı olamaz.');
      return false
    }


    changePassword(cookies.userData.id, userPassword, newPassword, cookies.access_token).then((response) => {
      console.log("changePassword response", response);
   
      setSuccessBox(true);
    })
      .catch((error) => {
        console.error('Bir hata oluştu:', error);
        setValidation('Mevcut şifreniz hatalı.');
      });
  }

  return (
    <>
      <div className={`modal modal-auth `}>
        <div
          className="modal-shadow"
          onClick={() => {
            setEvent(false)
          }}></div>
        <div className="modal-inner">
          <div className="modal-header">
            <span className="primary-title">
              <span>Şifre Değiştir</span>
            </span>
            <button
              className="btn-modal-close"
              onClick={() => {
                setEvent(false)
              }}>
              <img src="/assets/images/shapes/blue-shape-sm.svg" width={50} height={50} alt="Circle Shape" priority="true" />
              <img src="/assets/images/icon/close-white.svg" width={15} height={18} alt="Close" priority="true" />
            </button>
            <div className={'section-pattern reverse'}></div>
          </div>
          <div className="modal-body">
            <form onSubmit={handleChange} className="auth-modal-form">
              <span className={`valitation-text ${validation !== '' ? 'active' : ''}`}>{validation}</span>
              <div className="form-group">
                <label className="label" htmlFor="userPassword">
                  Mevcut Şifre:
                </label>
                <div className="icon-huge">
                  <Input
                    type={isPasswordText ? 'text' : 'password'}
                    className={'textbox'}
                    id={'userPassword'}
                    name={'userPassword'}
                    value={userPassword ? userPassword : ''}
                    onChange={passwordChange}
                  />
                  <button
                    className={`icon password ${!isPasswordText && 'open'}`}
                    id={'passwordToggle'}
                    type='button'
                    onClick={(e: any) => {
                      setIsPasswordText(!isPasswordText)
                    }}>
                    <img src="/assets/images/icon/show-hide.svg" width={24} height={24} alt=" " />
                  </button>
                </div>
              </div>

              <div className="form-group">
                <label className="label" htmlFor="newPassword">
                  Yeni Şifre:
                </label>
                <div className="icon-huge">
                  <Input
                    type={isNewsPasswordText ? 'text' : 'password'}
                    className={'textbox'}
                    id={'newPassword'}
                    name={'newPassword'}
                    value={newPassword ? newPassword : ''}
                    onChange={newPasswordChange}
                  />
                  <button
                    className={`icon password ${!isNewsPasswordText && 'open'}`}
                    id={'passwordToggle'}
                    type='button'
                    onClick={(e: any) => {
                      setIsNewsPasswordText(!isNewsPasswordText)
                    }}>
                    <img src="/assets/images/icon/show-hide.svg" width={24} height={24} alt=" " />
                  </button>
                </div>
              </div>

              <div className="primary-btn-huge">
                <button
                  className="btn btn-rounded btn-orange"
                  onClick={(e: any) => {

                  }}>
                  Gönder
                </button>
              </div>
              <div className={`auth-modal-form-result-box ${successBox ? 'active' : ''}`}>
                <img src="/assets/images/icon/check-circle.svg" width={40} height={40} alt="Check" />
                <span>Şifreniz değiştirildi.</span>
                <button
                  type='button'
                  className="btn btn-rounded btn-orange"
                  onClick={(e: any) => {
                    setSuccessBox(false)
                    setEvent(false)
                  }}>
                  Tamam
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
