import { useState, useEffect, useLayoutEffect } from 'react'
import { getEventById, getCompanyById } from '../services/api'
import moment from 'moment'
import { useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';

function EventDetail() {
  const { id } = useParams();

  const [data, setData] = useState(null)
  const [companyData, setCompanyData] = useState(null)

  const [cookies] = useCookies(['userData','access_token']);

  const redirectToPayment = () => {

    if(cookies && cookies.userData){ 
      window.location.href = "../payment/" + id; 
    }else{
      alert("Satın alma işlemi yapabilmeniz için lütfen giriş yapın.");
    }
  }

  useEffect(() => {
    async function getData() {
      await getEventById(id).then((response) => {
        console.log('event detail response', response.response)
        if (response.response !== null) {
          setData(response.response)
        }
      })
    }

    if (data === null && id) {
      getData()
    }
  }, [useParams, id])

  useEffect(() => {
    // if(data !== null && data.vendor_id !== null){
    //   async function getCompanyData() {
    //     await getCompanyById(data.vendor_id).then((response) => {
    //       console.log('company data response', response)
    //     })
    //   }
    //   getCompanyData() 
    // }
  }, [data])

  return (
    <>
      {data !== null && (
        <main id="event-detail-main">
          <section className={'section-pattern white reverse'}></section>
          <div className="container">

            {data.category === "Atölye" && (
              <>
                <div className="event-detail-title-box">
                  <span className="date-tag">
                    <strong>{moment(data.event_date).format('D')}</strong>
                    <span className="month">{moment(data.event_date).format('MMMM')}</span>
                    <span className="day">{moment(data.event_date).format('dddd')}</span>
                  </span>
                  <div>

                    <span className='category-elem'>{data.category && data.category} {data.sub_category && "/ " + data.sub_category} {data.sub_class && "/ " + data.sub_class}</span>
                    {/* <span className="time-with-icon">
                    <img src="/assets/images/icon/clock.svg" alt="..." width={24} height={24} />
                    <span>{moment(data.event_date).format('h:mm')}</span>
                  </span> */}
                    <h1 className="primary-title">{data.name}</h1>
                    <span className="company">{data.company_name}</span>
                  </div>
                </div>
              </>
            )}

            {data.category === "Kamp" && (
              <>
                <div className="event-detail-title-box">
                  <span className="date-tag">
                    <strong>{moment(data.start_date).format('D')}</strong>
                    <span className="month">{moment(data.start_date).format('MMMM')}</span>
                    <span className="day">{moment(data.start_date).format('dddd')}</span>
                  </span>
                  <div>

                    <span className='category-elem'>{data.category && data.category} {data.sub_category && "/ " + data.sub_category} {data.sub_class && "/ " + data.sub_class}</span>
                    {/* <span className="time-with-icon">
                    <img src="/assets/images/icon/clock.svg" alt="..." width={24} height={24} />
                    <span>{moment(data.event_date).format('h:mm')}</span>
                  </span> */}
                    <h1 className="primary-title">{data.name}</h1>
                    <span className="company">{data.company_name}</span>
                  </div>
                </div>
              </>
            )}

            {data.category === "Kurs" && (
              <>
                <div className="event-detail-title-box">
                  <span className="date-tag">
                    <strong>{moment(data.start_date).format('D')}</strong>
                    <span className="month">{moment(data.start_date).format('MMMM')}</span>
                    <span className="day">{moment(data.start_date).format('dddd')}</span>
                  </span>
                  <div>

                    <span className='category-elem'>{data.category && data.category} {data.sub_category && "/ " + data.sub_category} {data.sub_class && "/ " + data.sub_class}</span>
                    {/* <span className="time-with-icon">
                    <img src="/assets/images/icon/clock.svg" alt="..." width={24} height={24} />
                    <span>{moment(data.event_date).format('h:mm')}</span>
                  </span> */}
                    <h1 className="primary-title">{data.name}</h1>
                    <span className="company">{data.company_name}</span>
                  </div>
                </div>
              </>
            )}

            {data.category === "Tiyatro" && (
              <>
                <div className="event-detail-title-box">
                  <span className="date-tag">
                    <strong>{moment(data.event_date).format('D')}</strong>
                    <span className="month">{moment(data.event_date).format('MMMM')}</span>
                    <span className="day">{moment(data.event_date).format('dddd')}</span>
                  </span>
                  <div>

                    <span className='category-elem'>{data.category && data.category} {data.sub_category && "/ " + data.sub_category} {data.sub_class && "/ " + data.sub_class}</span>
                    {/* <span className="time-with-icon">
                    <img src="/assets/images/icon/clock.svg" alt="..." width={24} height={24} />
                    <span>{moment(data.event_date).format('h:mm')}</span>
                  </span> */}
                    <h1 className="primary-title">{data.name}</h1>
                    <span className="company">{data.company_name}</span>
                  </div>
                </div>
              </>
            )}

            <div className="event-detail-main-box">
              <div className="img">
                <img src={data.image} alt="..." width={600} height={400} />
              </div>

              {data.category === "Atölye" && (
                <div className="event-detail-sidebar">
                  <span className="primary-title">
                    {moment(data.event_date).format('D')} {moment(data.event_date).format('MMMM')} {moment(data.event_date).format('Y')}
                  </span>
                  <div className="sidebar-body">
                    <div>
                      <img src="/assets/images/icon/clock-white.svg" alt="..." width={20} height={20} />
                      <span>{data.start_time} - {data.end_time}</span>
                    </div>
                    <div>
                      <span>
                        {data.county}, {data.city}
                      </span>
                    </div>
                    {data.age_interval && (
                      <div>
                        <span>
                          Yaş Grubu: {data.age_interval}
                        </span>
                      </div>
                    )}
                    {data.capacity && (
                      <div>
                        <span>
                          Kontenjan: {data.capacity}
                        </span>
                      </div>
                    )}

                  </div>
                  <div className="btn-group">
                    <span className="price-badge">{data.price} ₺</span>
                    <button type='button' onClick={() =>{ redirectToPayment() }} className="btn btn-rounded btn-blue">
                      Şimdi satın al
                      <img src="/assets/images/icon/long-right-arrow.svg" alt="..." width={13} height={10} />
                    </button>
                  </div>
                </div>

              )}

              {data.category === "Kamp" && (
                <div className="event-detail-sidebar">
                  <span className="secondary-title">
                    Başlangıç Tarihi: {moment(data.start_date).format('D')} {moment(data.start_date).format('MMMM')} {moment(data.start_date).format('Y')}
                  </span>
                  <span className="secondary-title">
                    Bitiş Tarihi: {moment(data.end_date).format('D')} {moment(data.end_date).format('MMMM')} {moment(data.end_date).format('Y')}
                  </span>
                  <div className="sidebar-body border-top-padding">
                    <div>
                      <span>
                        {data.county}, {data.city}
                      </span>
                    </div>
                    {data.facility && (
                      <div>
                        <span>
                          Tesis: {data.facility}
                        </span>
                      </div>
                    )}
                    {data.age_interval && (
                      <div>
                        <span>
                          Yaş Grubu: {data.age_interval}
                        </span>
                      </div>
                    )}
                    {data.capacity && (
                      <div>
                        <span>
                          Kontenjan: {data.capacity}
                        </span>
                      </div>
                    )}

                  </div>
                  <div className="btn-group">
                    <span className="price-badge">{data.price} ₺</span>
                    <button type='button' onClick={() =>{ redirectToPayment() }} className="btn btn-rounded btn-blue">
                      Şimdi satın al
                      <img src="/assets/images/icon/long-right-arrow.svg" alt="..." width={13} height={10} />
                    </button>
                  </div>
                </div>

              )}

              {data.category === "Kurs" && (
                <div className="event-detail-sidebar">
                  <span className="secondary-title">
                    Başlangıç Tarihi: {moment(data.start_date).format('D')} {moment(data.start_date).format('MMMM')} {moment(data.start_date).format('Y')}
                  </span>
                  <span className="secondary-title">
                    Bitiş Tarihi: {moment(data.end_date).format('D')} {moment(data.end_date).format('MMMM')} {moment(data.end_date).format('Y')}
                  </span>
                  <div className="sidebar-body border-top-padding">
                    {(data.start_time && data.end_time) && (
                      <div>
                        <img src="/assets/images/icon/clock-white.svg" alt="..." width={20} height={20} />
                        <span>{data.start_time} - {data.end_time}</span>
                      </div>
                    )} 
                    <div>
                      <span>
                        {data.county}, {data.city}
                      </span>
                    </div>
                    {data.age_interval && (
                      <div>
                        <span>
                          Yaş Grubu: {data.age_interval}
                        </span>
                      </div>
                    )}
                    {data.capacity && (
                      <div>
                        <span>
                          Kontenjan: {data.capacity}
                        </span>
                      </div>
                    )}

                  </div>
                  <div className="btn-group">
                    <span className="price-badge">{data.price} ₺</span>
                    <button type='button' onClick={() =>{ redirectToPayment() }} className="btn btn-rounded btn-blue">
                      Şimdi satın al
                      <img src="/assets/images/icon/long-right-arrow.svg" alt="..." width={13} height={10} />
                    </button>
                  </div>
                </div>

              )}

              {data.category === "Tiyatro" && (
                <div className="event-detail-sidebar">
                  <span className="primary-title">
                    {moment(data.event_date).format('D')} {moment(data.event_date).format('MMMM')} {moment(data.event_date).format('Y')}
                  </span>
                  <div className="sidebar-body">
                    <div>
                      <img src="/assets/images/icon/clock-white.svg" alt="..." width={20} height={20} />
                      <span>{data.start_time} - {data.end_time}</span>
                    </div>
                    <div>
                      <span>
                        {data.county}, {data.city}
                      </span>
                    </div>
                    {data.age_interval && (
                      <div>
                        <span>
                          Yaş Grubu: {data.age_interval}
                        </span>
                      </div>
                    )}
                    {data.capacity && (
                      <div>
                        <span>
                          Kontenjan: {data.capacity}
                        </span>
                      </div>
                    )}
                    {data.stage && (
                      <div>
                        <span>
                        Sahne: {data.stage}
                        </span>
                      </div>
                    )}

                  </div>
                  <div className="btn-group">
                    <span className="price-badge">{data.price} ₺</span>
                    <button type='button' onClick={() =>{ redirectToPayment() }} className="btn btn-rounded btn-blue">
                      Şimdi satın al
                      <img src="/assets/images/icon/long-right-arrow.svg" alt="..." width={13} height={10} />
                    </button>
                  </div>
                </div>

              )}

            </div>

            <div className="event-detail-content-box">
              <div>
                <h2 className="primary-title">
                  Etkinlik <br /> Detayları
                </h2>
              </div>
              <div>
                <p>{data.description_text}</p>
              </div>
            </div>
            {/* <div className="event-detail-tag-box">
              <span className="primary-title">Konum Bilgisi:</span>
              <div>
                <p>
                  {data.county}, {data.city}
                </p>
              </div>
            </div> */}
            {(data.category === "Atölye" || data.category === "Kurs") && data.rules && (
              <>
                <div className="event-detail-tag-box">
                  <span className="primary-title">Adres:</span>
                  <div>
                    <p>
                      {data.address}
                    </p>
                  </div>
                </div>
                <div className="event-detail-tag-box">
                  <span className="primary-title">Etkinlik Kuralları:</span>
                  <div>
                    <p>
                      {data.rules}
                    </p>
                  </div>
                </div>
              </>
            )}
            {data.category === "Kamp" && data.transportation && (
              <>
                <div className="event-detail-tag-box">
                  <span className="primary-title">Adres:</span>
                  <div>
                    <p>
                      {data.address}
                    </p>
                  </div>
                </div>
                <div className="event-detail-tag-box">
                  <span className="primary-title">Ulaşım:</span>
                  <div>
                    <p>
                      {data.transportation}
                    </p>
                  </div>
                </div>
              </>
            )}

            {data.category === "Tiyatro" && (
              <>
                <div className="event-detail-tag-box">
                  <span className="primary-title">Notlar:</span>
                  <div>
                    <p>
                    Koltuk numaranız Organizatör tarafından otomatik olarak belirlenmektedir. <br/>
                    Biletinizi oyundan 1 saat önce gişeden alabilirsiniz. <br/>
                    Bu cümleler otomatik olarak her tiyatroda çıkmalı.
                    </p>
                  </div>
                </div>
                <div className="event-detail-tag-box">
                  <span className="primary-title">Tiyatro Kuralları:</span>
                  <div>
                    <p>
                      {data.rules}
                    </p>
                  </div>
                </div>
              </>
            )}

            <div className="company-info-card">
              <div className="img">
                <img src={data.vendor_image} alt="" />
              </div>
              <div className="detail">
                <strong className="primary-title">{data.company_name}</strong>
                <p>{data.about}</p>
                <a href={'../company/' + data.vendor_id} className="btn btn-rounded btn-orange">
                  Daha Fazla Bilgi Al
                  <img src="/assets/images/icon/long-right-arrow.svg" alt="..." width={13} height={10} />
                </a>
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  )
}

export default EventDetail
