
function Page() {
  return (
    <>
      <main id="blog-detail-main">
        <section className={'section-pattern white reverse'}></section>
        <section className="section-page-title">
          <div className="container">
            <div className="main-inner">
              <div>
                <h1 className="primary-title">ÖN BİLGİLENDİRME METNİ</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="section-blog-detail">
          <div className="container">
            <div className="editor-content">
              <strong> 1) SATICIYA AİT BİLGİLER</strong>
              <p>
                YETKİLİ​ : GÜLŞAH AZAKLI GÜNAY​ <br />
                VERGİ NUMARASI​: Sarıyer Vergi Dairesi (Vergi No: 1270313700) <br />
                E-POSTA​ : info@totilipotili.com <br />
                ADRES​ : Ferahevler Mah. Önceler Sok. No: 3 İç Kapı No: 1 Sarıyer/İSTANBUL <br />
              </p>

              <strong> 2) SÖZLEŞME KONUSU VE HİZMET NİTELİĞİ</strong>
              <p>
                Taraflar arasında akdedilecek sözleşmenin konusu Müşteriye, www.totilipotili.com internet sitesi veya totilipotili aplikasyonu üzerinden elektronik ortamda, etkinlik bileti alınmasına
                ilişkin olarak bilet satılması hizmetidir. Müşteri, etkinlik biletinin fiyatını internet sitesi veya aplikasyon üzerinden görebilecektir. Tüm harç ve vergiler etkinlik bilet fiyatına
                dahil olup, internet bilet satış hizmetine ilişkin ücrette www.totilipotili.com ve totilipotili aplikasyonunun değişiklik yapma hakkı saklıdır. Etkinlik biletleri, www.totilipotili.com
                veya totilipotili aplikasyonu üzerinden yalnızca banka kartı veya kredi kartı ile satın alınabilir. Müşteri, internet üzerinden bilet satın alması halinde kendisine ödeme yapmak üzere,
                tercih etmesi için kredi kartı veya banka kartı seçenekleri sunulacaktır. Müşteri, bu kredi kartı veya banka kartı seçeneğinden kendisine uygun olanı tercih edecek işlemini
                tamamlayacaktır. Gününde ve saatinde kullanılmayan etkinlik biletleri geçersiz olup, hizmet bedeli iadesi/ değişikliği yapılması mümkün değildir.
              </p>

              <strong> 3) CAYMA HAKKI</strong>
              <p>
                Müşteri, işbu sözleşmenin akdedildiği tarihten itibaren yedi gün içerisinde herhangi bir gerekçe göstermeksizin sözleşmeden cayma hakkına sahiptir. Müşterinin cayma hakkını
                kullanabilmesi için satın almış olduğu hizmeti hiçbir şekilde kullanmamış olması ve/veya cayma hakkı süresi olan 7 günlük süreyi aşmamak kaydıyla etkinlik seansının başlamasına 5
                günden fazla zaman kalması gerekmektedir, aksi halde cayma hakkı geçersiz olacaktır. Müşterinin cayma hakkını süresi içerisinde ve usulüne uygun kullanması halinde www.totilipotili.com
                ve totilipotili aplikasyonu cayma hakkı bildiriminin kendisine ulaştığı tarihten itibaren en geç on dört gün içerisinde almış olduğu bilete ilişkin bedeli Müşteriye iade edecektir.
                Müşteri, cayma hakkı kullanımını yazılı olarak yapacak veya www.totilipotili.com ve totilipotili aplikasyonunun belirtilen iletişim formuna veya e-posta adresine bildirerek
                gerçekleştirecektir.
              </p>

              <strong> 4) UYUŞMAZLIK ÇÖZÜMÜ</strong>

              <p>
                İşbu sözleşmenin uygulanmasından doğan uyuşmazlıklarda Bakanlık tarafından belirlenen parasal sınırlar dâhilinde Müşteri’nin hizmet satın aldığı veya ikametgâhının bulunduğu yerdeki
                Tüketici Sorunları Hakem Heyeti yetkili olacaktır. İşbu ön bilgilendirme metni Müşteri tarafından okunup, kabul edildikten sonra taraflar arasında mesafeli satış sözleşmesi kurulması
                aşamasına geçilecek olup, Müşteri bu metinde yer alan tüm maddeleri kabul ettiğini kabul, beyan ve taahhüt etmiş sayılacaktır.
              </p>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}

export default Page
