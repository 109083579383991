
import React, { useLayoutEffect, useState, useEffect } from 'react'
import Breadcrumb from '../components/Breadcrumb'

import BlogCategoryCard from '../components/BlogCategoryCard'
import { blogList } from '../services/api';

function Blog() {
    const [data, setData] = useState([])

    useLayoutEffect(() => {
        async function getData() {
            await blogList().then((response) => {
                // console.log('hero response', response.response)
                console.log("response", response)
                setData(response)
            })
        }
        getData()
        // blogs?category=Annelik
    }, [])

    return (
        <main id="blog-list-main">
            <Breadcrumb title="Blog" />
            <section className="blog-list-section">
                <div className="container">
                    <div className="list-container">
                        {data &&
                            data.map((item) => (
                                <BlogCategoryCard key={item.id} image={item.image} link={item.link} name={item.name} id={item.id} />
                            ))}
                        {/* <BlogCard />
          <BlogCard />
          <BlogCard />
          <BlogCard />
          <BlogCard />
          <BlogCard />
          <BlogCard />
          <BlogCard /> */}
                    </div>
                </div>
            </section>
        </main>
    )
}

export default Blog
