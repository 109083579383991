'use client'
import { useEffect, useLayoutEffect, useState } from 'react' 

import { getCategories } from '../services/api'

export default function Categories() {
  const [data, setData] = useState(null)
  useLayoutEffect(() => {
    async function getData() {
      await getCategories().then((response) => {
        // console.log('response', response)
        setData(response)
      })
    }
    getData()
  }, [])

  useEffect(() => {
    // console.log('data', data)
  }, [data])

  const dataDemo = [
    { id: 1, name: 'Item 1' },
    { id: 2, name: 'Item 2' },
    { id: 3, name: 'Item 3' },
  ]

  return (
    <> 
      <section id={'section-categories'} className="section p-xs">
        <div className="container">
          <div className="main-inner">
            {data &&
              data.map((item) => (
                <div key={item.id} className="category-card">
                  <img src={item.image} alt="Atolye" width={365} height={365} />
                  <strong className="card-title">{item.name}</strong>
                  <a href={'/events/'+ item.name} className="btn btn-rounded btn-yellow">
                    Şimdi İncele 
                  </a>
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  )
}
