

function Page() {
  return (
    <>
      <main id="blog-detail-main">
        <section className={'section-pattern white reverse'}></section>
        <section className="section-page-title">
          <div className="container">
            <div className="main-inner">
              <div>
                <h1 className="primary-title">KULLANICI SÖZLEŞMESİ</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="section-blog-detail">
          <div className="container">
            <div className="editor-content">
              <p>
                1) İşbu ‘‘Kullanıcı Sözleşmesi’’ www.totilipotili.com uzantılı internet sitemizde ve totilipotili aplikasyonunda yer alan hizmetlerden yararlanabilmeniz ve etkinliklere ilişkin
                biletleri satın alabilmeniz için gerekli kuralları içermektedir.
              </p>

              <p>
                2) İnternet sitemizi veya aplikasyonumuzu ziyaret ederek veya kullanarak işbu sözleşmede yer alan şartları okuduğunuzu, buradaki şartlarla bağlı olduğunuzu ve şartlarla ilgili
                yürürlükte bulunan tüm mevzuata uygun davranacağınızı açıkça kabul, beyan ve taahhüt etmiş olmaktasınız.
              </p>

              <p>3) İnternet sitemiz veya aplikasyon üzerinden satın almış olduğunuz biletinize ilişkin onay işlemi gerçekleştiğinde ödeme yükümlülüğünüz doğmuş olacaktır.</p>

              <p>
                4) İşbu sözleşmede yer alan tüm şartlar tarafımızca değiştirilebilecektir. Burada belirtilen tüm şartlar internet sitesinde ve aplikasyonda yayımlandığı anda yürürlüğe girecektir.{' '}
              </p>

              <p>5) Sitenin yazılımı ve içeriği tarafımıza ve tedarikçilere aittir ve Türk/Uluslararası telif hakkı mevzuatıyla korunmaktadır.</p>

              <p>6) Tarafımızca yetki verilmedikçe internet sitemizde veya aplikasyonumuzda yer alan görsel, içerik ve bilgiler herhangi bir amaçla kopyalanamayacaktır.</p>

              <p>
                7) İnternet sitemizde ve aplikasyonumuzda yer alan markalar, logolar, ilgili tüm ürün ve hizmet adları, ticari markaları ve hizmet markaları tarafımıza ve tedarikçilere aittir. Sitede
                yer alan telif hakkı, ticari marka veya patent uyarılarına uygun olmayan kopyalamalar telif yasası çerçevesinde gerçekleştirilebilecektir.
              </p>

              <p>
                8) İnternet sitemizin ve aplikasyonumuzun yasal olmayan şekilde veya yetkisiz olarak kullanılması yasak olup, yasağın ihlal edilmesi halinde gerekli yasal ve idari yollara
                başvurulacaktır.
              </p>

              <p>
                9) Üçüncü kişiler (tedarikçiler) tarafından yayımlanan tüm bilgi, içerik ve görsellerin ilgili mevzuata uygunluğunu teyit etmek bu bilgi, içerik ve görselleri internet sitemize
                gönderen kişilerin sorumluluğu altındadır. Aynı şekilde bu bilgi, içerik ve görsellerde çocukların yüzlerinin görülecek şekilde paylaşılması durumunda tüm hukuki ve cezai sorumluluk
                yine üçüncü kişilere (tedarikçilere) ait olacaktır.
              </p>

              <p>
                10) Kullanıcı olarak siteyi veya aplikasyonu hukuka aykırı olarak kullanmanız durumunda üçüncü şahıslar açısından ortaya çıkacak olan tüm zarar ve ziyanlar tarafınızdan tazmin
                edilecektir.
              </p>
              <p>
                11) İnternet sitemizi veya aplikasyonumuzu kullanan kullanıcılar ilgili mevzuat hükümlerine uymak zorunda olup, mevzuat hükümlerine uyulmaması sebebiyle ortaya çıkabilecek olan tüm
                zararlardan kendileri sorumlu olacaktır.
              </p>

              <p>
                12) İnternet sitemizin veya aplikasyonumuzun kullanımından kaynaklı olarak ortaya çıkabilecek olan zararlar kullanıcıların sorumluluğu altındadır. Site veya aplikasyon kullanımından
                kaynaklı zararlardan tarafımızın herhangi bir sorumluluğu bulunmamaktadır.
              </p>
              <p>
                13) Tarafımızın internet sitemizin veya aplikasyonumuzun kullanıcıları ve sponsorlarının gerçekleştirdikleri fiillerden hiçbir sorumluluğu bulunmamaktadır. Ayrıca internet sitemizin
                veya aplikasyonumuzun kullanımı esnasında kullanıcılar ve tedarikçiler tarafından ortaya çıkabilecek ihmallerden de herhangi bir sorumluluğumuz bulunmamaktadır.
              </p>
              <p>
                14) Belirtmiş olduğumuz sorumluluğumuzu sınırlandıran hükümlerin mahkeme tarafından ilgili mevzuat hükümlerine aykırılık teşkil etmesi durumunda mevzuatta tarafımıza en az sorumluluk
                yükleyen hüküm esas alınacak ve sorumluluğumuz bu kapsamda değerlendirilecektir.{' '}
              </p>
              <p>
                15) İnternet sitemizde veya aplikasyonumuzda yer alan tüm içerik ve hizmetlerin tarafımızca her koşulda değiştirme, kullanıcılar veya diğer üçüncü kişiler tarafından sitemize bilgimiz
                dışında yüklenen bilgileri ortadan kaldırma hakkımız bulunmaktadır.
              </p>

              <p>
                16) Üçüncü kişilere ait olan internet siteleri veya içeriklere yönlendirmede bulunmamız mümkün olup, yapmış olduğumuz yönlendirmelerin içeriğinde yer alan hizmetlere ilişkin olarak
                herhangi bir sorumluluğumuz bulunmamaktadır.
              </p>

              <p>
                17) Tarafımızca internet sitemizde veya aplikasyonumuzda yer alan hizmetlerin geçici olarak durdurulması, kapatılması mümkündür. Geçici süreyle durdurulan veya kapatılan hizmetlerden
                dolayı kullanıcılara karşı sorumluluğumuz bulunmamaktadır.
              </p>

              <p>
                18) İşbu Kullanıcı Sözleşmesi, satış politikamızı kapsamakta olup, kullanıcılar Mesafeli Satış Sözleşmemizi ve Gizlilik Sözleşmemizi de kabul ettiklerini ve sözleşmelerde yer alan tüm
                hükümlerle bağlı olduklarını kabul, beyan ve taahhüt etmektedirler.
              </p>

              <p>
                19) İnternet sitemizin veya aplikasyonumuzun kullanımıyla beraber ortaya çıkabilecek uyuşmazlıklarda Türkiye Cumhuriyeti kanunlarına tabi olunacak ve bu kanunlar çerçevesinde
                uyuşmazlıklar çözüme kavuşturulacaktır. Ortaya çıkabilecek olan tüm uyuşmazlıklarda İstanbul mahkemeleri ve icra daireleri görevli ve yetkili olacaktır.
              </p>

              <p>
                20) İşbu Kullanıcı Sözleşmesinde yer alan hükümler ihlal edildiğinde tarafımızca internet sitemizi veya aplikasyonumuzu kullanım hakkının sona erdirilmesi mümkün olup, yasal yollara
                müracaat etme hakkımız da saklı tutulmaktadır.
              </p>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}

export default Page
